import React, { Component, Fragment } from 'react';

import { restRequest } from '../../../../Helpers/RequestHelper';
import { NewItemIcon } from '../../../../Assets/Navigation/NavigationIcons';
import HeaderWrapper from '../../../../Components/HeaderWrapper/HeaderWrapper';
import Box from '../../../../Components/Layout/Box';
import { ActionMenu } from '../../../../Components/CheckedMenu/CheckedMenu';
import ListTableImageColumn from '../../../../Components/ListTable/ListTableImageColumn';
import Table from '../../../../Components/Table/Table';
import { MdClose } from '../../../../Common/Icons';
import Loader from '../../../../Components/Loader/Loader';
import NotFound from '../../../../Components/NotFound/NotFound';
import {
  setValueInLS,
  getObjFromLS,
  setObjInLS,
} from '../../../../Helpers/LocalStorage';
import { checkError } from '../../../../Helpers/AuthHelper';
import './itemGroupDetail.css';
import ItemDetailsImages from '../../ItemDetails/Info/ItemDetailsImages';
import ImportIcon from '../../../../Assets/General/ImportIcon';
import AddRetrunReceive from '../../../../Assets/General/AddRetrunReceive';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import OpenStockTable from './OpenStockTable';
import { TempItemMappedModal } from '../../../../Common';
import AccessDenied from '../../../../Components/AccessDenied/AccessDenied';
import { HeaderMenus } from '../../../../Components';

class ItemGroupDetails extends Component {
  id = this.props.id
    ? this.props.id
    : this.props.idFromHeaderWrapper
    ? this.props.idFromHeaderWrapper
    : this.props.match.params.id;
  state = {
    itemGroup: {},
    // itemGroupOpenStock: {},
    currency: {},
    loading: true,
    notFound: false,
    detailsNo: '',
    showOpeningStock: false,
    showConfirmationModal: false,
    confirmationModalDataItem: {},
    confirmationModalLoading: false,
    confirmationModalWoocomList: [],
    confirmationModalBtnLoader: false,
  };

  componentDidMount() {
    if (!this.props.inModal) {
      document.title = 'Item Group Details';
    }
    this.fetchData();
    //await this.fetchOpenStockData()
  }

  fetchData() {
    this.setState({ loading: true });
    restRequest('get', `itemgroups/${this.id}`)
      .then((res) => {
        this.setState({
          itemGroup: res,
          // itemGroupOpenStock: res,
          detailsNo: res.name,
          currency: res.currency,
          loading: false,
        });
        // this.fetchOpenStockData();
        let dataFromLS = getObjFromLS('module');
        setObjInLS('module', {
          ...dataFromLS,
          presistDetailPage: true,
        });
      })
      .catch((err) => {
        //console.log('err1', err)
        checkError(err);
        this.setState({ notFound: true, loading: false });
      });
  }

  renderActionMenu() {
    const [hasCreatePermission, hasEditPermission, hasDeletePermission] =
      this.hasPermission('itemGroups', 'All');
    const { is_active } = this.state.itemGroup;

    const menusList = [
      hasCreatePermission && {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () =>
          this.props.history.push(`/item-groups/addItem/${this.id}`),
        label: `Add Variant`,
        icon: 'new',
      },
      {
        type: 'link',
        icon: 'edit',
        enable: hasEditPermission || false,
        to: `/item-groups/edit/${this.id}`,
        tooTip: 'Edit',
        mobileLable: 'Edit',
        isMobile: true,
      },
      {
        type: 'button',
        icon: 'delete',
        tooTip: 'Delete',
        mobileLable: 'Delete',
        isMobile: true,
        handleClick: () =>
          this.openDeleteModal(
            this.id,
            this.props.forceRedirect,
            this.props.forceRedirectFlag
          ),
        enable: hasDeletePermission || false,
      },
      {
        type: 'button',
        icon: is_active ? 'inactive' : 'active',
        tooTip: is_active ? 'inactive' : 'active',
        isMobile: true,
        mobileLable: is_active ? 'inactive' : 'active',
        enable: hasEditPermission || false,
        handleClick: () =>
          this.toggleActive(this.id, !is_active ? 'active' : 'inactive'),
      },
      hasCreatePermission && {
        type: 'button',
        className: 'button--primary float-left mr-3',
        handleClick: () => this.props.history.push(`/item-groups/add`),
        label: `New`,
        icon: 'new',
      },
    ];
    return (
      <ActionMenu>
        <HeaderMenus menusList={menusList} loading={this.state.loading} />
      </ActionMenu>
    );
  }

  renderItemGroupDetailsInfo() {
    const { itemGroup } = this.state;
    const {
      unit,
      description,
      total_items,
      item_attribute,
      manufacturer,
      brand,
    } = itemGroup;
    return (
      <div className="section-details--info">
        <div className="field-row">
          <div className="section-details--info-title semi-bold heading_font">
            Total Variant(s)
          </div>
          <div className="semi-bold">
            {total_items} {/*{unit}(s)*/}
          </div>
        </div>
        <hr className="bottom_line" />

        <div className="field-row">
          <div className="section-details--info-title semi-bold heading_font">
            Unit
          </div>
          <div className="semi-bold">{unit}</div>
        </div>
        <hr className="bottom_line" />

        <div className="field-row">
          <div className="section-details--info-title semi-bold heading_font">
            Manufacturer
          </div>
          <div className="semi-bold">{manufacturer}</div>
        </div>
        <hr className="bottom_line" />

        <div className="field-row">
          <div className="section-details--info-title semi-bold heading_font">
            Brand
          </div>
          <div className="semi-bold">{brand}</div>
        </div>
        <hr className="bottom_line" />

        {item_attribute &&
          item_attribute.map((attr, index) => (
            <div className="brand_top" key={index}>
              <div className="field-row">
                <div className="section-details--info-title semi-bold heading_font">
                  {attr.name}
                </div>
                <div className="item-group-main semi-bold">
                  {attr.item_attribute_option &&
                    attr.item_attribute_option.map((opt, index) => (
                      <div className="item-tag" key={index}>
                        {opt.name}
                      </div>
                    ))}
                </div>
              </div>
              <hr className="bottom_line" />
            </div>
          ))}
        <div className="section-details--info-title semi-bold heading_font">
          Description
        </div>
        <div>
          <p className="desc-set">{description}</p>
        </div>
      </div>
    );
  }

  renderLayoutView() {
    const { itemGroup } = this.state;
    const { images } = itemGroup;
    return (
      <Fragment>
        <div className="float-left w-100 item_group_summary_update position-relative items_group_main">
          <ItemDetailsImages images={images} inModal={true} />
          {this.renderItemGroupDetailsInfo()}
        </div>
      </Fragment>
    );
  }

  tempModalhandler = (itemData) => {
    const { storeType = '', store_id = null } = itemData;
    const url =
      storeType === 'shopify'
        ? `shopify/items/ims/${store_id}`
        : `woocom/items/ims/${store_id}`;

    this.setState(
      {
        confirmationModalLoading: true,
        showConfirmationModal: true,
        confirmationModalDataItem: itemData,
      },
      () => {
        restRequest('get', url)
          .then((res) => {
            if (res?.ims_items?.length) {
              let options = res?.ims_items.map((option, i) => {
                const stock_preference = res.stock_preference;
                let ims_stock;
                if (stock_preference === 'physical_stock') {
                  ims_stock = option.physical_stock;
                } else {
                  ims_stock = option.accounting_stock;
                }
                return {
                  ...option,
                  label: option.name,
                  value: option.id,
                  ims_stock: ims_stock,
                };
              });
              this.setState({ confirmationModalWoocomList: options }, () => {
                this.setState({ confirmationModalLoading: false });
              });
            } else {
              // this.props.handleToast('Ims items not found', 'error');
              // this.setState({ showConfirmationModal: false });
              this.setState({ confirmationModalLoading: false });
            }
          })
          .catch((error) => {
            this.props.handleToast(error, 'error');
            this.setState({ showConfirmationModal: false });
          });
      }
    );
  };

  prepareItemsData(items, currency) {
    let tempItems = [];
    let itemsArray = items.map((item, index) => ({
      name: (
        <ListTableImageColumn
          name={item.name}
          sku={item.sku}
          key={index}
          images={item.images}
          showModalUponClick={!this.props.inModal}
          id={item.id}
          association={item.association}
        />
      ),
      stock: `${item.stock_on_hand}`,
      reorder_level: `${item.reorder_level}`,
      selling_price: `${currency.symbol}${item.sales_unit_price}`,
      purchase_price: `${currency.symbol}${item.purchase_unit_price}`,
    }));
    if (this.state?.itemGroup?.temp_items?.length !== 0) {
      tempItems = this.state.itemGroup?.temp_items?.map((item, index) => ({
        name: (
          <span
            onClick={() => {
              const itemData = {
                id: item.sync_item_id,
                name: item.name,
                sku: item.sku,
                store_id: item.store_id,
                store_stock: item.market_place_stock,
                market_place_item_id: item.market_place_item_id,
                storeType: item.market_place,
              };
              this.tempModalhandler(itemData);
            }}
          >
            <ListTableImageColumn
              name={item.name}
              sku={item.sku}
              key={index}
              images={item.images}
              //showModalUponClick={!this.props.inModal}
              id={item.id}
              unSyncIcon={true}
              unSyncText={`This item is not mapped`}
            />
          </span>
        ),
        stock: `${item.stock_on_hand}`,
        reorder_level: `${item.reorder_level}`,
        selling_price: `${currency.symbol}${item.sales_unit_price}`,
        purchase_price: `${currency.symbol}${item.purchase_unit_price}`,
        rowColor: true,
      }));
    }
    return [...itemsArray, ...tempItems];
  }

  renderSidebar() {
    const preparedData =
      typeof this.state.itemGroup.items !== 'undefined'
        ? this.prepareItemsData(this.state.itemGroup.items, this.state.currency)
        : [];
    const tableHeaders = [
      'Item',
      'Cost Price',
      'Selling Price',
      'Stock On Hand',
      'Reorder Level',
    ];
    const tableData = [
      'name',
      'purchase_price',
      'selling_price',
      'stock',
      'reorder_level',
    ];
    return (
      <Box
        className={`item-groups-tab item-groups-table float-left w-100 ${
          this.props?.match?.params?.id ? 'single-item_table-set' : ''
        }`}
      >
        <div className="height_custom_item_group scrollbar_style">
          <Table
            list={preparedData}
            tableHeaders={tableHeaders}
            tableData={tableData}
            className="details-table group_items_table"
            rowColor
          />
          <p className="__notItemsFound">
            {preparedData.length === 0 ? 'No Items Found' : null}
          </p>
        </div>
      </Box>
    );
  }

  prepareItemsOpenStockData(items, currency) {
    return items.map((item) => ({
      id: `${item.id}`,
      name: `${item.name}`,
      sku: `${item.sku}`,
      inventory_type: item.inventory_type,
      stock: item.stocks.map((stock) => ({
        warehouse_name: `${stock.warehouse.name}`,
        warehouse_primary: `${stock.warehouse.is_primary}`,
        warehouse_active: `${stock.warehouse.is_active}`,
        open_quantity: `${stock.opening_quantity}`,
        open_stock_value: `${currency.symbol}${parseFloat(
          stock.opening_quantity * item.purchase_unit_price
        ).toFixed(2)}`,
      })),
    }));
  }

  renderOpenStockData() {
    if (this.state.itemGroup.items !== undefined) {
      const preparedOpenStockData = this.prepareItemsOpenStockData(
        this.state.itemGroup.items,
        this.state.currency
      );
      return (
        <div className="float-left w-100">
          <OpenStockTable list={preparedOpenStockData} />
        </div>
      );
    }
  }

  closeAllModals = () => {
    this.setState({
      showOpeningStock: false,
    });
  };

  handleOpeningStockModal() {
    this.setState({ showOpeningStock: true });
  }

  renderOpeningStock() {
    return (
      <CustomModal
        showModal={this.state.showOpeningStock}
        title="Opening Stock Distribution"
        Icon={AddRetrunReceive}
        fill="#000000"
        size="large"
        className="stock_distribution_popup"
        renderActions={false}
        onClose={this.closeAllModals}
        classNames="opening-stock-popup"
      >
        {this.renderOpenStockData()}
      </CustomModal>
    );
  }

  // <-------- ConfirmationDialog Handlers
  closeConfirmationModalHandler = () => {
    this.setState({ showConfirmationModal: false });
  };
  btnClicksHandler = ({ id = null, type = '', ...rest }) => {
    this.setState({ confirmationModalBtnLoader: true });
    let payload;
    const {
      store_id = null,
      mappedItemData = {},
      stockSelect = '',
      stockSelectValue = '',
      market_place_item_id = null,
      sync_item_name = '',
      storeType = '',
    } = rest;

    let url =
      storeType === 'shopify'
        ? 'shopify/items/associate_items'
        : 'woocom/items/associate_items';

    if (type === 'confirm') {
      payload = {
        store_id: store_id,
        items: [
          {
            inventory_type: 'inventory',
            item_id: 0,
            market_place_item_id: market_place_item_id,
            // rowIndex: 0,
            stock: stockSelectValue,
            stock_status: stockSelect,
            sync_item_id: id,
            sync_item_name: sync_item_name,
          },
        ],
      };
    } else if (type === 'exist') {
      payload = {
        store_id: store_id,
        items: [
          {
            inventory_type: mappedItemData.inventory_type,
            item_id: mappedItemData.id,
            market_place_item_id: market_place_item_id,
            // rowIndex: 0,
            stock: stockSelectValue,
            stock_status: stockSelect,
            sync_item_id: id,
            sync_item_name: sync_item_name,
          },
        ],
      };
    }
    restRequest('post', url, payload)
      .then((res) => {
        this.setState({ showConfirmationModal: false });
        this.props.handleToast(res.message, 'success');
        this.fetchData();
        this.setState({ confirmationModalBtnLoader: false });
      })
      .catch((error) => {
        this.setState({ confirmationModalBtnLoader: false });
        this.props.handleToast(error.message, 'error');
      });
  };
  // ConfirmationDialog Handlers ----->

  render() {
    const hasViewPermission = this.hasViewPermission('itemGroups');

    if (this.state.loading) return <Loader />;
    if (this.state.notFound) return <NotFound />;
    var secondaryButtonLabel = 'Opening Stock Distribution';
    return (
      <Fragment>
        <TempItemMappedModal
          itemData={this.state.confirmationModalDataItem}
          showModal={this.state.showConfirmationModal}
          closeModal={this.closeConfirmationModalHandler}
          confirmBtnClicks={this.btnClicksHandler}
          itemsList={this.state.confirmationModalWoocomList}
          loading={this.state.confirmationModalLoading}
          btnLoader={this.state.confirmationModalBtnLoader}
        />
        {!hasViewPermission && <AccessDenied type="section" />}

        {hasViewPermission && (
          <div className="item-group-fragment float-left ui-itemgroup-icon">
            {!this.props.inModal && (
              <div className="close_icon">
                <MdClose
                  onClick={() => {
                    let dataFromLS = getObjFromLS('module');
                    setObjInLS('module', {
                      ...dataFromLS,
                      presistDetailPage: false,
                    });
                    setValueInLS('fullscreen', 'true');
                    sessionStorage.setItem('once', true);
                    this.props.history.push('/r');
                  }}
                />
              </div>
            )}
            <div
              className={`float-left w-80 border-right-set ${
                this.props?.match?.params?.id ? 'item-group-single' : ''
              }`}
            >
              {this.renderLayoutView()}
              {!this.props.inModal && secondaryButtonLabel && (
                <p
                  onClick={() => this.handleOpeningStockModal()}
                  className="item_view_click_btn opening_stock_button"
                >
                  <ImportIcon className="open-stock-distribution" />
                  {secondaryButtonLabel}
                </p>
              )}
              {this.renderOpeningStock()}
              {this.renderSidebar()}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default HeaderWrapper(ItemGroupDetails, {
  name: 'Item Groups',
  tableIcon: 'general_module_icon icon_top',
  deleteName: 'Item Group',
  Icon: NewItemIcon,
  baseUrl: 'itemgroups',
  redirectUrl: '/item-groups',
  onlyMenu: true,
  showName: true,
  permissionName: 'itemGroups',
});
