import { Formik, Field, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { restRequest } from '../../Helpers/RequestHelper';
import Toaster from '../../Helpers/Toaster/Toaster';
import classNames from 'classnames';

const GeneralModule = ({
  state,
  handleClose,
  handleSubmit,
  module_permission,
}) => {
  const [modules, setModules] = useState(module_permission);

  useEffect(() => {
    restRequest('get', 'general_modules').then((res) => {
      setModules(res);
    });
  }, []);

  // const handleChange = (value) => {
  //   let modulevalues = modules
  //   let changeobj = modulevalues.find((module) => module.slug === value)
  //   let changeobjIndex = modulevalues.findIndex((module) => module.slug === value)
  //   changeobj = {
  //     ...changeobj,
  //     status: changeobj.status === 0 ? 1 : 0
  //   }

  //   modulevalues[changeobjIndex] = changeobj

  //   setModules([...modulevalues])
  // }

  const submit = () => handleSubmit(modules);

  return (
    <div>
      <Formik onSubmit={submit}>
        {() => (
          <Form>
            <h4 style={{ textAlign: 'center' }}>
              Are you sure you want to share & switch{' '}
              <b>{state?.orgSwitchName}</b> Organization?
            </h4>
            {/* <div >
              {modules?.map((module) => (
                <React.Fragment key={module.slug}>
                  <label style={{ marginBottom: '8px' }}>
                    <Field
                      type="checkbox"
                      name={module.slug}
                      checked={module.status}
                      disabled={(module.slug === 'item' || module.slug === 'contact') ? true : false}
                      onChange={() => handleChange(module.slug)}
                      style={{ marginLeft: '3px', marginRight: '5px' }}
                    />
                    {module.name}
                  </label>
                  <br />
                </React.Fragment>
              )

              )}
            </div> */}

            {/* <div className={`dropdwon_with_button float-right`} style={{ marginBottom: "10px" }}>
              <button

                className="dropdwon_with_button_button"
                type="submit"
              >
                Share & Switch
              </button>

              <button

              // onClick={onClose}
              >
                'Cancel'
              </button>
            </div> */}

            <div className="ims-modal--action">
              <button
                className={classNames(
                  'button',
                  'ims-modal--action--cancel cancel_button'
                )}
                onClick={(event) => {
                  event.preventDefault();
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button
                id="Click_Delete_Button"
                className={classNames(
                  'button',
                  'ims-modal--action--confirm confitm_button'
                )}
                type="submit"
                disabled={state?.loading}
              >
                Confirm
              </button>
            </div>
          </Form>
        )}
      </Formik>{' '}
    </div>
  );
};

export default GeneralModule;
