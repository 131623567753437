import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './PageNumber.css';

const PageNumber = ({
  totalPage,
  currentPage,
  handleSelectedPage,
  nextPage,
  previousPage,
  // route,
  // paginationLength = 5,
  history,
}) => {
  function handleChange(e) {
    let value = e.target.value;
    if (value > totalPage) {
      value = totalPage;
    } else if (!value) {
      value = 0;
    }
    setPageValue(value);
  }
  const [pageValue, setPageValue] = useState(currentPage);
  useEffect(() => {
    setPageValue(currentPage);
  }, [currentPage]);
  return (
    <div className={classNames('page-number-container')}>
      <div
        onClick={() => previousPage()}
        className={`button pointer arrows left_arrow ${
          pageValue < 2 ? 'disabled-link' : ''
        }`}
      >
        <svg
          viewBox="0 0 20 20"
          className={classNames('page-number-arrow--icon')}
        >
          <path d="M20 0 L10 10 L20 20" />
        </svg>
      </div>

      <input
        type="number"
        className={classNames('pagination-input')}
        onChange={(e) => handleChange(e)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            return history.push(handleSelectedPage(pageValue));
          }
        }}
        value={pageValue}
      />
      <div
        onClick={() => nextPage()}
        className={`button pointer arrows right_arrow ml_3 ${
          pageValue === totalPage ? 'disabled-link' : ''
        }`}
      >
        <svg
          viewBox="0 0 20 20"
          className={classNames('page-number-arrow--icon')}
        >
          <path d="M0 0 L10 10 L0 20" />
        </svg>
      </div>
      <div className={classNames('show_total')}>of {totalPage}</div>
    </div>
  );
};

export default PageNumber;
