import React from 'react';

import { getCurrentOrganization } from '../../../Helpers/CurrentSession';
import { imagePathS3 } from '../../../Helpers/RequestHelper';
import Default_logo from '../../../Assets/Img/org-image.jpg';
// import { getAddress } from '../../Organization/ChooseOrganization'
// import {StateShortName} from "../../../Helpers/StateNameConvertion";
const ReportOrganization = () => {
  const {
    name: organizationName,
    logo,
    company_city: companyCity,
    company_province: companyState,
    company_postal_code: companyZip,
    country = {},
    company_street: companyStreet,
    get_type: type,
  } = getCurrentOrganization();

  return (
    <div className="reports--organization-info">
      <div className="reports--organization-title">
        <div className="reports_image_circle">
          {logo ? (
            <img src={imagePathS3(logo)} alt={organizationName} />
          ) : (
            <img src={Default_logo} alt="ims" />
          )}
        </div>
        <div className="uiux_organization-main">
          <h4 className="report-organization">{organizationName}</h4>
          <span className="organization-label">{type.label}</span>
          <div
            style={{ marginTop: '5px', fontWeight: '300', fontSize: '12px' }}
          >
            <span>{companyStreet}</span>
          </div>
          <div
            style={{ marginTop: '5px', fontWeight: '300', fontSize: '12px' }}
          >
            {/* <span>{getAddress(companyCity)}</span> */}{' '}
            {/* USE this function*/}
            {/*<span>{companyCity}{(companyCity || companyState) && ', '}{StateShortName(companyState)} {companyZip && companyZip}{companyZip && country && ', '}{country && country.country_name}</span>*/}
            <span>
              {companyCity && companyCity + ', '}
              {companyState && companyState + ', '}
              {companyZip && companyZip + ', '}
              {country && country.country_name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportOrganization;
