import React, { Component } from 'react';
import classNames from 'classnames';
import { imagePath } from '../../../../Helpers/RequestHelper';
import { GoPlus as Upload } from '../../../../Common/Icons';
import Loader from '../../../../Components/Loader/Loader';
import FileUploader from '../../../../Components/FileUploader/FileUploader';

import './ItemDetailsImages.css';
import { ImageUrl } from '../../../../Helpers/ImageUrl';

class ItemDetailsImages extends Component {
  state = {
    currentImage: '',
  };

  componentDidMount() {
    //this.setState({ currentImage: this.props.images[0] })
    if (this.props.primary_image && this.props.primary_image['image_path']) {
      this.setState({ currentImage: this.props.primary_image['image_path'] });
    } else {
      this.setState({ currentImage: this.props.images[0] });
    }
  }

  render() {
    const { currentImage } = this.state;
    const { images, primary_image, onDropImages, imageUploading, setPrimary } =
      this.props;
    return (
      <div className="item-details-images-container">
        {!this.props.inModal &&
          primary_image &&
          currentImage !== primary_image['image_path'] && (
            <button
              className="chip_btn chip-btn-set"
              onClick={() => setPrimary(currentImage)}
            >
              {' '}
              Set as primary
            </button>
          )}
        {!this.props.inModal && !primary_image && images.length > 0 && (
          <button
            className="chip_btn chip-btn-set"
            onClick={() => setPrimary(currentImage)}
          >
            Set as primary
          </button>
        )}
        {!this.props.inModal &&
          primary_image &&
          currentImage === primary_image['image_path'] && (
            <button className="chip_btn chip_btn_success chip-btn-set">
              Primary
            </button>
          )}
        {imageUploading ? (
          <Loader className="no-margin no-padding item-details-image-upload" />
        ) : (
          <img
            src={
              currentImage ? ImageUrl(currentImage) : imagePath(currentImage)
            }
            alt=""
            className="item-details-image--current"
          />
        )}
        <div className="item-details-images-list">
          {images?.map((image, index) => {
            return (
              <div key={index} className="image_upladed">
                <img
                  key={index}
                  src={image ? ImageUrl(image) : imagePath(image)}
                  alt=""
                  className={classNames(
                    'item-details-image pointer',
                    this.state.currentImage === image ? 'selected' : ''
                  )}
                  onClick={() => this.setState({ currentImage: image })}
                />
              </div>
            );
          })}
          {imageUploading ? (
            <Loader className="no-margin no-padding item-details-image-upload" />
          ) : !this.props.inModal && this.props.images.length < 5 ? ( // hide if already five images
            <div className="file_upload">
              <FileUploader
                className="pointer item-details-image-upload"
                accept="image/*"
                onDrop={onDropImages}
                title="Change logo"
                customLabel={<Upload />}
                multiple={false}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ItemDetailsImages;
