import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './ImageCropper.css';
import FormAction from '../../Components/Form/FormAction';

export default function ImageCropper(props) {
  const cropperRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [zoom, setZoom] = useState(0.1);
  const [rotation, setRotation] = useState(0);

  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      // Get cropped image as a base64 URL
      const croppedDataURL = cropper.getCroppedCanvas().toDataURL();
      setCroppedImage(croppedDataURL);
      props.onSubmitModal(props.updatedFiles, croppedDataURL);
    }
  };

  const handleSkipCrop = (skip) => {
    if (skip) {
      const file = props.updatedFiles[props.updatedFiles.length - 1];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        props.onSubmitModal(props.updatedFiles, reader.result);
      };
      reader.onerror = (error) => console.error('Error reading file:', error);
    } else {
      handleCrop();
    }
  };

  return (
    <div className="image_modal_popup">
      <div className="position-relative parent_container">
        <Cropper
          src={props.updatedFiles[props.updatedFiles.length - 1].preview}
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={1}
          guides={false}
          zoomTo={zoom}
          rotateTo={rotation}
          viewMode={2} // Restrict the crop box to the image boundaries
          autoCropArea={1} // Make the crop area cover the image
          cropBoxResizable={true}
          cropBoxMovable={true}
          ref={cropperRef}
          className="custom-cropper"
        />
      </div>

      <div className="controls">
        <div className="float-left w-50 justify_content_end">
          <label className="zoom_image">Scale</label>
          <input
            type="range"
            min="0.1"
            max="3"
            step="0.01"
            value={zoom}
            onChange={(e) => {
              const zoomValue = parseFloat(e.target.value);
              setZoom(zoomValue);
              const cropper = cropperRef.current?.cropper;
              if (cropper) cropper.zoomTo(zoomValue);
            }}
          />
        </div>
        <div className="float-left w-50 position_relative">
          <label className="rotate_image">Rotate</label>
          <input
            type="range"
            min="0"
            max="360"
            step="1"
            value={rotation}
            onChange={(e) => {
              const rotationValue = parseInt(e.target.value, 10);
              setRotation(rotationValue);
              const cropper = cropperRef.current?.cropper;
              if (cropper) cropper.rotateTo(rotationValue);
            }}
          />
        </div>
      </div>

      <FormAction
        disabled={props.alreadyUploadInprogress}
        onSubmit={handleCrop}
        onCancel={props.closeModal}
        submitLabel="Save"
        marginFromRight={0}
        showSaveAndSendButton={true}
        saveAndSendField={{
          label: 'Save Original',
          onClick: () => handleSkipCrop(true),
          disabled: props.alreadyUploadInprogress,
        }}
      />

      {!props.inModal && croppedImage && (
        <img src={croppedImage} alt="Cropped" />
      )}
    </div>
  );
}
