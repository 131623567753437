import React, { Fragment, useState } from 'react';

import getDate from '../../../../../Helpers/Date/GetDate';
import { imagePathS3 } from '../../../../../Helpers/RequestHelper';
import getStatusColor from '../../../../../Helpers/GetStatusColor';
import {
  UserIcon,
  LocationIcon,
  PhoneIcon,
  CompanyOffice,
} from '../../../../../Assets/Contact/OverviewContact';
import { getAddress } from '../../../../../Pages/Organization/ChooseOrganization';
import { NewContactIcon } from '../../../../../Assets/Navigation/NavigationIcons';
import { NewSalesOrderIcon } from '../../../../../Assets/Navigation/NavigationIcons';
import CustomModal from '../../../../../Components/CustomModal/CustomModal';
import Customer from '../../../../../Pages/Contact/ContactDetails/index';
import SalesOrderDetails from '../../../SalesOrderDetails';
import { ImageUrl } from '../../../../../Helpers/ImageUrl';
export default ({ invoice, currency: { symbol }, inModal, billingAddress }) => {
  const {
    dueDate,
    // total,
    invoiceDate,
    // paymentDue,
    orderNo,
    invoiceTermName,
    customer,
    invoiceNo,
    salesOrderNos = [],
    salesOrderId,
    status,
    salesPerson,
    customer_name,
  } = invoice;
  const currentOrganization = JSON.parse(
    localStorage.getItem('currentOrganization')
  );

  const [showContactModal, SetContactModal] = useState(false);
  const [showSOModal, setShowSOModal] = useState(false);
  const contactCreateModal = () => {
    return (
      <CustomModal
        size="large"
        className="contacts_modal_view"
        showModal={showContactModal}
        title="View Contact"
        // minWidth={120}
        modaltitle="inventory_modal_title"
        Icon={NewContactIcon}
        renderActions={false}
        onClose={() => SetContactModal(false)}
        linkTo={`contacts/${customer.id}`}
      >
        <Customer id={customer.id} inModal />
      </CustomModal>
    );
  };
  function viewSaleOrderModal() {
    return (
      <CustomModal
        classNames="package-saleorder-view view-details-popup popup-height"
        showModal={showSOModal}
        title="View Sales Order"
        renderActions={false}
        size="large"
        Icon={NewSalesOrderIcon}
        linkTo={`salesorders/${salesOrderId}`}
        onClose={() => setShowSOModal(false)}
      >
        <SalesOrderDetails id={salesOrderId} inModal={true} />
      </CustomModal>
    );
  }
  return (
    <div className="float-left w-100">
      {contactCreateModal()}
      {viewSaleOrderModal()}
      <div className="invoice_details bg_">
        <div className="left_sender_Sec">
          <div className="image">
            <img
              src={
                currentOrganization.logo
                  ? ImageUrl(currentOrganization?.logo) || ''
                  : imagePathS3(currentOrganization?.logo) || ''
              }
              alt="logo-here"
            />
          </div>
          <div className="desc">
            <div
              className="Name des-row font_bold color_custom mb_2"
              style={{ wordBreak: 'break-word' }}
            >
              {currentOrganization?.name && (
                <div className="icon">
                  <UserIcon customWidth="20" customHeight="15" />
                </div>
              )}{' '}
              <span>
                {currentOrganization?.name ? currentOrganization?.name : ''}
              </span>{' '}
            </div>
            <div
              className="country des-row  color_custom mb_2"
              style={{ wordBreak: 'break-word' }}
            >
              {currentOrganization?.company_street ||
              currentOrganization?.company_city ||
              currentOrganization?.company_province ||
              currentOrganization?.company_postal_code ? (
                <Fragment>
                  <div className="icon">
                    <LocationIcon customWidth="20" customHeight="15" />
                  </div>
                  <span>
                    {currentOrganization?.company_street && (
                      <span style={{ textDecoration: 'none' }}>
                        {currentOrganization?.company_street}
                        {', '}
                      </span>
                    )}
                    <span>
                      {currentOrganization?.company_city &&
                        currentOrganization?.company_city}
                      {currentOrganization?.company_city &&
                        currentOrganization?.company_province &&
                        ', '}
                      {/*{StateShortName(currentOrganization?.company_province)} {currentOrganization?.company_postal_code && currentOrganization?.company_postal_code}*/}
                      {currentOrganization?.company_province}{' '}
                      {currentOrganization?.company_postal_code &&
                        currentOrganization?.company_postal_code}
                      {currentOrganization?.country && ', '}
                      {currentOrganization?.country
                        ? currentOrganization?.country.country_name
                        : 'United States '}
                    </span>
                  </span>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="icon">
                    <LocationIcon customWidth="20" customHeight="15" />
                  </div>
                  <span>
                    {currentOrganization?.country
                      ? currentOrganization?.country.country_name
                      : 'United States '}
                  </span>
                </Fragment>
              )}
            </div>
            <div
              className="contact des-row color_custom mb_2"
              style={{ wordBreak: 'break-word' }}
            >
              {
                <div className="icon">
                  <PhoneIcon customWidth="20" customHeight="15" />
                </div>
              }{' '}
              <span>
                {currentOrganization?.phone
                  ? 'Office: ' + currentOrganization?.phone
                  : ''}
              </span>{' '}
              {/*| Cell: +1 202 555 0193*/}{' '}
            </div>
          </div>
        </div>
        <div className="right_sender_Sec">
          <div className="right_sender_Sec_heading heading_color_custom">
            Invoice
          </div>
          <div className="invoice_number fs_16">
            <span className="bold">Invoice No. :</span> {invoiceNo}
          </div>
          <div className="float-right" style={{ marginTop: '10px' }}>
            <span style={{ margin: '0' }} className={getStatusColor(status)}>
              {status === 'partially paid' ? 'PRTL Paid' : status}
            </span>
          </div>
        </div>
      </div>

      <div className="invoices_desc">
        <div className="float-left w-60">
          <div className="float-left w-100">
            <div className="bill_2 float-left w-100 border_bottom_custom">
              Bill To
            </div>
            <div className="invoices_desc_left_child float-left w-100">
              <div className="Name float-left w-100 color_custom text inv_billto">
                <div className="icon">
                  <UserIcon customWidth="20" customHeight="15" />
                </div>
                {inModal ? (
                  <span>{customer_name ? customer_name : ''}</span>
                ) : (
                  <span
                    className="text_underline pointer inv_name"
                    onClick={() => SetContactModal(true)}
                  >
                    {customer_name ? customer_name : ''}
                  </span>
                )}
              </div>
              {customer.company_name && (
                <div className="Name float-left w-100 color_custom text inv_billto">
                  <div className="icon">
                    <CompanyOffice customWidth="20" customHeight="15" />
                  </div>
                  <span>
                    {customer.company_name ? customer.company_name : ''}
                  </span>
                </div>
              )}

              <div className="country float-left w-100 color_custom">
                {
                  <Fragment>
                    <div className="icon">
                      <LocationIcon customWidth="20" customHeight="15" />
                    </div>
                    <div
                      className="desc"
                      style={{ float: 'left', width: '90%' }}
                    >
                      {getAddress(billingAddress.street) && (
                        <span>{getAddress(billingAddress.street)}</span>
                      )}
                      <span>
                        {getAddress(billingAddress.city) &&
                          getAddress(billingAddress.city) + ', '}
                        {getAddress(billingAddress.state) &&
                          getAddress(billingAddress.state) + ', '}
                        {getAddress(billingAddress.zip_code) &&
                          getAddress(billingAddress.zip_code) + ', '}
                        {getAddress(billingAddress.country_name)
                          ? getAddress(billingAddress.country_name)
                          : 'United States'}
                      </span>
                    </div>
                  </Fragment>
                }
              </div>
              <div>
                {billingAddress.phone && (
                  <div className="contact float-left w-100 color_custom">
                    <div className="icon">
                      {' '}
                      <PhoneIcon customWidth="20" customHeight="15" />
                    </div>
                    <span>
                      {' '}
                      {billingAddress.phone ? billingAddress.phone : ''}{' '}
                    </span>{' '}
                    {/*| Cell: +1 202 555 0193*/}{' '}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="float-left w-40">
          <div className="bill_2 float-left w-100 border_bottom_custom">
            Invoice Details
          </div>
          <div className="invoices_desc_right_child float-left w-100">
            <div className="float-left w-100 bt_custom">
              <div className="left_Sec section-details--info-title semi-bold">
                {' '}
                Invoice Date{' '}
              </div>
              <div className="left_Sec "> {getDate(invoiceDate)} </div>
            </div>
            <div className="float-left w-100 bt_custom">
              <div className="left_Sec section-details--info-title semi-bold">
                {' '}
                Due Date{' '}
              </div>
              <div className="left_Sec "> {getDate(dueDate)} </div>
            </div>
            {orderNo && (
              <div className="float-left w-100 bt_custom">
                <div className="left_Sec section-details--info-title semi-bold">
                  {' '}
                  Order No{' '}
                </div>
                {inModal ? (
                  <div className="left_Sec "> {orderNo} </div>
                ) : (
                  <div
                    className={`text_underline left_Sec ${
                      salesOrderNos.length ? 'pointer' : ''
                    }`}
                    style={{ float: 'right' }} // comment by Afzaal  UI_DEV_REQUIRED Ticket: 4724
                    onClick={() => salesOrderNos.length && setShowSOModal(true)}
                  >
                    {orderNo}
                  </div>
                )}
              </div>
            )}

            {salesPerson && (
              <div className="float-left w-100 bt_custom">
                <div className="left_Sec section-details--info-title semi-bold">
                  {' '}
                  Sales Rep{' '}
                </div>
                <div className="left_Sec "> {salesPerson} </div>
              </div>
            )}
            {invoiceTermName && (
              <div className="float-left w-100 bt_custom">
                <div className="left_Sec section-details--info-title semi-bold">
                  {' '}
                  Invoice Terms{' '}
                </div>
                <div className="left_Sec "> {invoiceTermName} </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
