import React, { Component } from 'react';
import { imagePathS3 } from '../../../../../Helpers/RequestHelper';
import ContactAddressTab from '../Address/ContactAddressTab';
import ContactPersonTab from './../ContactPerson/ContactPersonTab';
import {
  CellIcon,
  UserIcon,
  ContactCard,
  FaxIcon,
  PhoneIcon,
  CurrencyIcon,
} from '../../../../../Assets/Contact/OverviewContact';
import Avatar from '../../../../../Components/Avatar/Avatar';

import './OverViewContact.css';
import { ImageUrl } from '../../../../../Helpers/ImageUrl';
class OverViewContact extends Component {
  render() {
    const { inModal } = this.props;
    return (
      <div className="container-contact-overviw">
        <div className="tab_content_contacts float-left w-100">
          <div className="porfile_2">
            <div className="image">
              {this.props.contactInfo.photo ? (
                <img
                  src={ImageUrl(this.props.contactInfo.photo)} //imagePathS3 where type is all
                  className="organization-logo avatar"
                  alt="contact-pic"
                />
              ) : (
                <Avatar>{this.props.contactInfo.display_name.charAt(0)}</Avatar>
              )}
            </div>
            <div className="desc">
              <div className="name">{this.props.contactInfo.display_name}</div>
              <div className="company_name">
                {this.props.contactInfo.company_name}
              </div>
              <div className="license_no" title={'License No./ Permit No.'}>
                License No.:{' '}
                {this.props.contactInfo.license_no
                  ? this.props.contactInfo.license_no
                  : 'Not Allocated'}
              </div>
              <div className="platform">
                Origin: {this.props.contactInfo.platform_type}
              </div>
              <div className="contact_type">
                <span>{this.props.contactInfo.contact_type}</span>
              </div>
            </div>
          </div>
          {/* contact info markup */}
          {/* <div className="contact-info-main">
            <div className="contact-info-row d-flex align-center flex-wrap">
              <div className="contact-info-left word-break">
                <p className="semi-bold">Outstanding Receivables</p>
              </div>
              <div className="contact-info-right word-break">
                <p className="semi-bold font_custom lh-25">$0</p>
              </div>
            </div>
            <div className="contact-info-row d-flex align-center">
              <div className="contact-info-left">
                <p className="semi-bold">Unused Credits</p>
              </div>
              <div className="contact-info-right word-break">
                <p className="semi-bold lh-25">$0</p>
              </div>
            </div>
            <div className="contact-info-row d-flex align-center">
              <div className="contact-info-left word-break">
                <p className="semi-bold mb-0">Payment Due Period</p>
              </div>
              <div className="contact-info-right word-break">
                <p className="semi-bold  mb-0">Due End of This Month</p>
              </div>
            </div>
          </div> */}

          {/* contact info markup */}
          <div className="contatcs_all_details">
            <div className="due float-left w-100">
              <div className="left_sec d-flex align-center">
                <div className="contact-info-main">
                  <div className="contact-info-row d-flex align-center flex-wrap">
                    <div className="contact-info-left word-break">
                      <p className="semi-bold">
                        Outstanding
                        {this.props.contactInfo.contact_type === 'vendor'
                          ? ' Payables'
                          : ' Receivables'}
                      </p>
                    </div>
                    <div className="contact-info-right word-break">
                      <p className="semi-bold font_custom lh-25">
                        {this.props.contactInfo.currency.symbol}
                        {this.props.contactInfo.transaction_summary &&
                        this.props.contactInfo.transaction_summary
                          .total_receivable != null
                          ? this.props.contactInfo.transaction_summary.total_receivable.toFixed(
                              2
                            )
                          : this.props.contactInfo.transaction_summary
                              .total_outstanding_payable != null
                          ? this.props.contactInfo.transaction_summary.total_outstanding_payable.toFixed(
                              2
                            )
                          : 0.0}
                      </p>
                    </div>
                  </div>
                  {this.props.contactInfo.contact_type !== 'vendor' && (
                    <div className="contact-info-row d-flex align-center">
                      <div className="contact-info-left">
                        {this.props.contactInfo.contact_type !== 'vendor' && (
                          <p className="">Unused Credits</p>
                        )}
                      </div>
                      <div className="contact-info-right word-break">
                        {this.props.contactInfo.contact_type !== 'vendor' && (
                          <p className="semi-bold">
                            {this.props.contactInfo.unused_credits &&
                            this.props.contactInfo.unused_credits != null
                              ? this.props.contactInfo.unused_credits
                              : `${this.props.contactInfo.currency.symbol}0`}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  {this.props.contactInfo.invoice_terms &&
                    this.props.contactInfo.invoice_terms != null && (
                      <div className="contact-info-row d-flex align-center">
                        <div className="contact-info-left word-break">
                          {this.props.contactInfo.invoice_terms &&
                          this.props.contactInfo.invoice_terms != null ? (
                            <p className="mb-0">
                              {this.props.contactInfo.contact_type ===
                              'customer'
                                ? 'Payment Due Period'
                                : ''}
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="contact-info-right word-break">
                          <p className="semi-bold  mb-0">
                            {(this.props.contactInfo.invoice_terms &&
                              this.props.contactInfo.invoice_terms != null) ||
                            this.props.contactInfo.invoice_terms === 0
                              ? this.props.contactInfo.invoice_terms.name
                              : ''}
                          </p>
                        </div>
                      </div>
                    )}
                </div>
                {/* <div className="inner">
                  <p className="semi-bold">
                    Outstanding
                    {this.props.contactInfo.contact_type === 'vendor'
                      ? ' Payables'
                      : ' Receivables'}
                  </p>
                  {this.props.contactInfo.contact_type !== 'vendor' && (
                    <p className="">Unused Credits</p>
                  )}
                  {this.props.contactInfo.invoice_terms &&
                    this.props.contactInfo.invoice_terms != null ? (
                    <p className="mb-0">
                      {this.props.contactInfo.contact_type === 'customer'
                        ? 'Payment Due Period'
                        : ''}
                    </p>
                  ) : (
                    ''
                  )}
                </div> */}
                {/* <div className="inner on-m-w40 word-break contact-summary">
                  <p className="semi-bold font_custom lh-20">
                    {this.props.contactInfo.currency.symbol}
                    {this.props.contactInfo.transaction_summary &&
                      this.props.contactInfo.transaction_summary
                        .total_receivable != null
                      ? this.props.contactInfo.transaction_summary
                        .total_receivable
                      : this.props.contactInfo.transaction_summary
                        .total_outstanding_payable != null
                        ? this.props.contactInfo.transaction_summary
                          .total_outstanding_payable
                        : 0}
                  </p>
                  {this.props.contactInfo.contact_type !== 'vendor' && (
                    <p className="semi-bold">
                      {this.props.contactInfo.unused_credits &&
                        this.props.contactInfo.unused_credits != null
                        ? this.props.contactInfo.unused_credits
                        : `${this.props.contactInfo.currency.symbol}0`}
                    </p>
                  )}
                  <p className="semi-bold  mb-0">
                    {(this.props.contactInfo.invoice_terms &&
                      this.props.contactInfo.invoice_terms != null) ||
                      this.props.contactInfo.invoice_terms === 0
                      ? this.props.contactInfo.invoice_terms.name
                      : ''}
                  </p>
                </div> */}
              </div>

              <div
                className={
                  this.props.contactInfo.contact_type === 'vendor'
                    ? 'hidden'
                    : 'right_sec'
                }
              >
                <div
                  className={
                    this.props.contactInfo.contact_type === 'vendor'
                      ? 'hidden'
                      : 'inner text-center'
                  }
                >
                  <p>Items to be Invoiced</p>
                  <p>Items to be Shipped</p>
                  <p className="mb-0">Items to be Packed</p>
                </div>
                <div
                  className={
                    this.props.contactInfo.contact_type === 'vendor'
                      ? 'hidden'
                      : 'inner text-center inner on-m-w40'
                  }
                >
                  <p className="custom_color">
                    {this.props.contactInfo.transaction_summary &&
                    this.props.contactInfo.transaction_summary
                      .total_to_be_invoiced != null
                      ? this.props.contactInfo.transaction_summary
                          .total_to_be_invoiced
                      : '0'}
                  </p>
                  <p className="custom_color">
                    {this.props.contactInfo.transaction_summary &&
                    this.props.contactInfo.transaction_summary
                      .total_to_be_shipped != null
                      ? this.props.contactInfo.transaction_summary
                          .total_to_be_shipped
                      : '0'}
                  </p>
                  <p className="mb-0 custom_color">
                    {this.props.contactInfo.transaction_summary &&
                    this.props.contactInfo.transaction_summary
                      .total_to_be_packed != null
                      ? this.props.contactInfo.transaction_summary
                          .total_to_be_packed
                      : '0'}
                  </p>
                </div>
              </div>

              <div className="right_sec">
                <div
                  className={
                    this.props.contactInfo.contact_type === 'customer'
                      ? 'hidden'
                      : 'inner text-center'
                  }
                >
                  <p>Items to be Received</p>
                  <p>Items Ordered</p>
                  <p className="mb-0">Total Payable</p>
                </div>
                <div
                  className={
                    this.props.contactInfo.contact_type === 'customer'
                      ? 'hidden'
                      : 'inner text-center inner on-m-w40'
                  }
                >
                  <p className="custom_color">
                    {this.props.contactInfo.transaction_summary &&
                    this.props.contactInfo.transaction_summary
                      .total_items_to_be_received != null
                      ? this.props.contactInfo.transaction_summary
                          .total_items_to_be_received
                      : '0'}
                  </p>
                  <p className="custom_color">
                    {this.props.contactInfo.transaction_summary &&
                    this.props.contactInfo.transaction_summary
                      .total_items_ordered != null
                      ? this.props.contactInfo.transaction_summary
                          .total_items_ordered
                      : '0'}
                  </p>
                  <p className="mb-0 custom_color">
                    {this.props.contactInfo.currency.symbol}
                    {this.props.contactInfo.transaction_summary &&
                    this.props.contactInfo.transaction_summary.total_payable !=
                      null
                      ? this.props.contactInfo.transaction_summary.total_payable.toFixed(
                          2
                        )
                      : '0.00'}
                  </p>
                </div>
              </div>
            </div>
            {/* More Details */}
            <div className="more_details float-left w-100">
              <span>More Details</span>
              <div className="left_sec">
                <ul className="float-left w-100">
                  <li className="details_row">
                    <UserIcon customWidth="20" customHeight="15" />
                    <span className="details_data">
                      {this.props.contactInfo.salutation &&
                        this.props.contactInfo.salutation + ' '}
                      {`${this.props.contactInfo.first_name} ${this.props.contactInfo.last_name}`}
                    </span>
                  </li>
                  {(this.props.contactInfo.department ||
                    this.props.contactInfo.designation) && (
                    <li className="details_row">
                      <ContactCard customWidth="20" customHeight="15" />
                      <span className="details_data">
                        {this.props.contactInfo.department &&
                          this.props.contactInfo.department}
                        {this.props.contactInfo.department &&
                          this.props.contactInfo.designation &&
                          ', '}
                        {this.props.contactInfo.designation}
                      </span>
                    </li>
                  )}
                  {(this.props.contactInfo.work_phone ||
                    this.props.contactInfo.work_phone_secondary) && (
                    <li className="details_row">
                      <PhoneIcon customWidth="20" customHeight="15" />
                      <span className="details_data">
                        {this.props.contactInfo.work_phone &&
                          this.props.contactInfo.work_phone}
                        {this.props.contactInfo.work_phone &&
                          this.props.contactInfo.work_phone_secondary &&
                          ' | '}
                        {this.props.contactInfo.work_phone_secondary &&
                          this.props.contactInfo.work_phone_secondary}
                      </span>
                    </li>
                  )}
                  {(this.props.contactInfo.mobile ||
                    this.props.contactInfo.mobile_secondary) && (
                    <li className="details_row">
                      <CellIcon customWidth="20" customHeight="15" />
                      <span className="details_data">
                        {this.props.contactInfo.mobile &&
                          this.props.contactInfo.mobile}
                        {this.props.contactInfo.mobile &&
                          this.props.contactInfo.mobile_secondary &&
                          ' | '}
                        {this.props.contactInfo.mobile_secondary &&
                          this.props.contactInfo.mobile_secondary}
                      </span>
                    </li>
                  )}
                  {this.props.contactInfo.contact_addresses.length > 0 &&
                    this.props.contactInfo.contact_addresses[0].fax && (
                      <li className="details_row">
                        <FaxIcon customWidth="20" customHeight="15" />
                        <span className="details_data">
                          {this.props.contactInfo.contact_addresses[0].fax}
                        </span>
                      </li>
                    )}
                  <li className="details_row">
                    <CurrencyIcon customWidth="20" customHeight="15" />
                    <span className="details_data">
                      Currency Code:
                      <b> {this.props.contactInfo.currency.currency_code}</b>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="right_sec">
                <ul>
                  {this.props.contactInfo.website && (
                    <li>
                      <a
                        title={`Website: ${this.props.contactInfo.website}`}
                        href={
                          this.props.contactInfo.website &&
                          this.props.contactInfo.website.includes('http')
                            ? `${this.props.contactInfo.website}`
                            : `https://${this.props.contactInfo.website}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.props.contactInfo.website}
                      </a>
                    </li>
                  )}
                  {this.props.contactInfo.email && (
                    <li>
                      <a
                        title={`Email: ${this.props.contactInfo.email}`}
                        href={`mailto:${this.props.contactInfo.email}`}
                      >
                        {this.props.contactInfo.email}
                      </a>
                    </li>
                  )}
                  {this.props.contactInfo.facebook && (
                    <li>
                      <a
                        title={`https://www.facebook.com/${this.props.contactInfo.facebook}`}
                        href={`https://www.facebook.com/${this.props.contactInfo.facebook}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.facebook.com/
                        {this.props.contactInfo.facebook}
                      </a>
                    </li>
                  )}
                  {this.props.contactInfo.twitter && (
                    <li>
                      <a
                        title={`https://twitter.com/${this.props.contactInfo.twitter}`}
                        href={`https://twitter.com/${this.props.contactInfo.twitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.twitter.com/
                        {this.props.contactInfo.twitter}
                      </a>
                    </li>
                  )}
                  {this.props.contactInfo.instagram && (
                    <li>
                      <a
                        title={`https://www.instagram.com/${this.props.contactInfo.instagram}`}
                        href={`https://www.instagram.com/${this.props.contactInfo.instagram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.instagram.com/
                        {this.props.contactInfo.instagram}
                      </a>
                    </li>
                  )}
                  {this.props.contactInfo.skype && (
                    <li title={`Skype:${this.props.contactInfo.skype}`}>
                      Skype : {this.props.contactInfo.skype}
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div>
              <ContactAddressTab
                inModal={inModal}
                contactId={this.props.contactId}
                handleToast={this.props.handleToast}
              />
              <ContactPersonTab
                inModal={inModal}
                contactId={this.props.contactId}
                handleToast={this.props.handleToast}
              />
              {this.props.contactInfo.note && (
                <>
                  <p className="semi-bold">Notes</p>
                  <span
                    style={{
                      marginBottom: '20px',
                      float: 'left',
                      width: '100%',
                    }}
                  >
                    {this.props.contactInfo.note}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OverViewContact;
