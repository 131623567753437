import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MdHelpOutline } from '../../../Common/Icons';
import { restRequest } from '../../../Helpers/RequestHelper';
import { EMAIL_OPTIONS } from '../../../Constants';
import OverlayLoader from '../../../Components/Loader/OverlayLoader';
import Form from '../../../Components/Form/Form';
import Field from '../../../Components/Form/Field/Field';
import { Checked } from '../../../Assets/List/ListIcons';
import FormAction from '../../../Components/Form/FormAction';
import {
  getObjFromLS,
  // setObjInLS
} from '../../../Helpers/LocalStorage';

import './EmailPrefrence.css';
import { checkError } from '../../../Helpers/AuthHelper';
import { isEqual } from 'lodash';

class EmailPrefrence extends Component {
  state = {
    loading: true,
    staticVal: {},
    showPrompt: false,
    email: '',
    isSubmitting: false,
  };

  componentDidMount() {
    localStorage.setItem('alert', false);
    this.fetchData();
    window.onbeforeunload = function () {
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { terms, staticVal } = this.state;

    if (prevState.showPrompt !== this.state.showPrompt) {
      const checkEquality = Boolean(isEqual(terms, staticVal));
      if (!checkEquality) localStorage.setItem('alert', true);
      else localStorage.setItem('alert', false);
    }
  }

  handlePrompt = () => {
    this.setState({
      showPrompt: !this.state.showPrompt,
    });
  };

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', 'preferences/email')
      .then((res) => {
        this.setState({
          email: res.preferences.email,
          staticVal: res.preferences.email,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let payload = {
      status: {
        email: this.state.email,
      },
    };
    this.setState({ isSubmitting: true });
    restRequest('put', 'preferences/email', payload)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.handleToast(
          'Email preference updated successfully',
          'success'
        );
        this.props.setPrompt(false);
        this.fetchData();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });
        checkError(error);
        this.props.handleToast(error, 'error');
      });
  };

  renderActionButtons() {
    let permissions = getObjFromLS('role').permissions;
    if (!permissions.superAccess) {
      if (permissions.PreferenceEdit) {
        return (
          <FormAction
            disabled={this.state.isSubmitting || !this.state.terms}
            onCancel={() => this.props.history.push('/settings')}
          />
        );
      }
    }
    if (permissions.superAccess) {
      return (
        <FormAction
          disabled={this.state.isSubmitting}
          onCancel={() => this.props.history.push('/settings')}
        />
      );
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    this.handlePrompt();
  };

  invoiceTermsContent() {
    return (
      <div className="float-left w-100 invoice-terms-pref">
        <Field
          name="Email Text"
          id="email"
          type="textarea"
          value={this.state.email}
          placeholder="Email Text"
          handleChange={this.handleChange}
        />
      </div>
    );
  }

  renderStatusChangeInput() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="invoice-preference float-left w-100">
          <div className="float-left w-70 genral_tab_content general_tabs_set">
            {this.state.loading && <OverlayLoader />}
            {this.invoiceTermsContent()}
            {this.renderActionButtons()}
          </div>
        </div>
      </Form>
    );
  }

  render() {
    return this.renderStatusChangeInput();
  }
}

export default withRouter(EmailPrefrence);
