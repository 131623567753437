import React from 'react';
import { imagePath } from '../../Helpers/RequestHelper';
import { ImageUrl } from '../../Helpers/ImageUrl';

export default ({ image = '', name }) => (
  <span className="list-table--user--col">
    <div>
      <img
        src={image ? ImageUrl(image) : imagePath(image, 'user')}
        alt={'profile'}
      />
    </div>
    <span className="name_align">{name}</span>
  </span>
);
