import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import getStatusColor from '../../Helpers/GetStatusColor';
import moment from 'moment';
import './TabsTable.css';
import getDateFormat from '../../Helpers/Date/GetDateFormat';
import EmptyTable from '../ListTable/EmptyTable';
import Loader from '../Loader/Loader';
import NotesIcon from '../../Assets/General/NotesIcon';
import { useParams } from 'react-router-dom';

const headers = (data) => {
  if (data[0]) {
    return Object.keys(data[0]).filter(
      (value) => value !== 'id' && value !== 'additional'
    );
  }

  return [];
};

const renderStatus = (status) => (
  <span className={getStatusColor(status)}>{status}</span>
);

const renderData = (array, key) => {
  const value = array[key].getValue();
  if (array[key].alignRight) return <div className="text-right">{value}</div>;
  return value;
};

const spreadValues = (arrayElement, tableHeaders, hasCheckbox) => {
  const values = [];
  for (let key in arrayElement) {
    if (key === 'id') continue;
    if (!hasCheckbox) {
      values.push(renderData(arrayElement, key));
      continue;
    }
    if (tableHeaders.includes(key)) {
      if (
        ['Status', 'status'].includes(key) &&
        !arrayElement[key].hideStatusColor
      ) {
        values.push(renderStatus(arrayElement[key].getValue()));
        continue;
      } else if (
        [
          'Date',
          'Order Date',
          'Bill Due Date',
          'date',
          'Package Date',
          'Delivery Date',
          'Due Date',
          'Invoice Date',
          'Return Date',
          'Credit Note Date',
        ].includes(key)
      ) {
        let tempDate = 0;
        if (
          ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
        ) {
          let DatePartition = arrayElement[key]?.getValue()
            ? arrayElement[key]?.getValue()?.split(' ')
            : '';
          // console.log({ DatePartition });
          tempDate = DatePartition[0]?.replace(/[-.]/g, '/');
        } else {
          tempDate = arrayElement[key]
            .getValue()
            .split(' ')
            .join(' ')
            .replace(/[-|.]/g, '/');
        }
        let finalDate = tempDate;
        if (
          getDateFormat() === 'DD/MM/YYYY' ||
          getDateFormat() === 'DD/MM/YY'
        ) {
          let newTempDate = tempDate.split('/');
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            if (newTempDate[0].length === 2) {
              newTempDate[0] = '20' + newTempDate[0];
            }
          } else if (newTempDate[2].length === 2) {
            newTempDate[2] = '20' + newTempDate[2];
          }
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          } else {
            finalDate =
              newTempDate[1] + '/' + newTempDate[0] + '/' + newTempDate[2];
          }
        } else if (
          getDateFormat() === 'DD-MM-YYYY' ||
          getDateFormat() === 'DD-MM-YY'
        ) {
          let newTempDate = tempDate.split('/');
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            if (newTempDate[0].length === 2) {
              newTempDate[0] = '20' + newTempDate[0];
            }
          } else if (newTempDate[2].length === 2) {
            newTempDate[2] = '20' + newTempDate[2];
          }
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          } else {
            finalDate =
              newTempDate[1] + '/' + newTempDate[0] + '/' + newTempDate[2];
          }
        } else if (
          getDateFormat() === 'DD.MM.YYYY' ||
          getDateFormat() === 'DD.MM.YY'
        ) {
          let newTempDate = tempDate.split('/');
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            if (newTempDate[0].length === 2) {
              newTempDate[0] = '20' + newTempDate[0];
            }
          } else if (newTempDate[2].length === 2) {
            newTempDate[2] = '20' + newTempDate[2];
          }
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          } else {
            finalDate =
              newTempDate[1] + '/' + newTempDate[0] + '/' + newTempDate[2];
          }
        } else if (
          getDateFormat() === 'MM/DD/YYYY' ||
          getDateFormat() === 'MM/DD/YY'
        ) {
          let newTempDate = tempDate.split('/');
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            if (newTempDate[0].length === 2) {
              newTempDate[0] = '20' + newTempDate[0];
            }
          } else if (newTempDate[2].length === 2) {
            newTempDate[2] = '20' + newTempDate[2];
          }
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          } else {
            finalDate =
              newTempDate[0] + '/' + newTempDate[1] + '/' + newTempDate[2];
          }
        } else if (
          getDateFormat() === 'MM-DD-YYYY' ||
          getDateFormat() === 'MM-DD-YY'
        ) {
          let newTempDate = tempDate.split('/');
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            if (newTempDate[0].length === 2) {
              newTempDate[0] = '20' + newTempDate[0];
            }
          } else if (newTempDate[2].length === 2) {
            newTempDate[2] = '20' + newTempDate[2];
          }
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          } else {
            finalDate =
              newTempDate[0] + '/' + newTempDate[1] + '/' + newTempDate[2];
          }
        } else if (
          getDateFormat() === 'MM.DD.YYYY' ||
          getDateFormat() === 'MM.DD.YY'
        ) {
          let newTempDate = tempDate.split('/');
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            if (newTempDate[0].length === 2) {
              newTempDate[0] = '20' + newTempDate[0];
            }
          } else if (newTempDate[2].length === 2) {
            newTempDate[2] = '20' + newTempDate[2];
          }
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          } else {
            finalDate =
              newTempDate[0] + '/' + newTempDate[1] + '/' + newTempDate[2];
          }
        } else if (
          getDateFormat() === 'YYYY/MM/DD' ||
          getDateFormat() === 'YY/MM/DD'
        ) {
          let newTempDate = tempDate.split('/');
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            if (newTempDate[0].length === 2) {
              newTempDate[0] = '20' + newTempDate[0];
            }
          } else if (newTempDate[0].length === 2) {
            newTempDate[0] = '20' + newTempDate[0];
          }
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          } else {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          }
        } else if (
          getDateFormat() === 'YYYY-MM-DD' ||
          getDateFormat() === 'YY-MM-DD'
        ) {
          let newTempDate = tempDate.split('/');
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            if (newTempDate[0].length === 2) {
              newTempDate[0] = '20' + newTempDate[0];
            }
          } else if (newTempDate[0].length === 2) {
            newTempDate[0] = '20' + newTempDate[0];
          }
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          } else {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          }
        } else if (
          getDateFormat() === 'YYYY.MM.DD' ||
          getDateFormat() === 'YY.MM.DD'
        ) {
          let newTempDate = tempDate.split('/');
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            if (newTempDate[0].length === 2) {
              newTempDate[0] = '20' + newTempDate[0];
            }
          } else if (newTempDate[0].length === 2) {
            newTempDate[0] = '20' + newTempDate[0];
          }
          if (
            ['Package Date', 'Due Date', 'Date', 'Invoice Date'].includes(key)
          ) {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          } else {
            finalDate =
              newTempDate[1] + '/' + newTempDate[2] + '/' + newTempDate[0];
          }
        }

        values.push(moment(finalDate).format(getDateFormat()));
        continue;
      }
      values.push(renderData(arrayElement, key));
    }
  }
  return values;
};

const renderHeader = (
  header,
  filters,
  sortFilter,
  orderBy,
  generateRoute,
  isHeaderRightAlign
) => {
  const flatten = [].concat(...filters);
  const index = flatten.indexOf(header);
  if (index !== -1)
    return (
      <th
        key={header}
        className={`${header === 'Date' ? 'date' : ''} ${
          header === 'Type' ? 'type' : ''
        } ${isHeaderRightAlign ? 'text-right' : ''}`}
      >
        <Link
          className="list-table--sort-header"
          to={generateRoute({
            sort: flatten[index + 1],
            orderBy: orderBy === 'asc' ? 'desc' : 'asc',
          })}
        >
          <span
            className={classNames(
              'pointer',
              sortFilter === flatten[index + 1]
                ? orderBy === 'asc'
                  ? 'ascending'
                  : 'descending'
                : null
            )}
          >
            {header}
          </span>
        </Link>
      </th>
    );
  return (
    <th
      key={header}
      className={`${header === 'Date' ? 'date' : ''}  ${
        header === 'Type' ? 'type' : ''
      } ${isHeaderRightAlign ? 'text-right' : ''}`}
    >
      {header}
    </th>
  );
};

const isHeaderRightAlign = (header, data) => {
  if (data[0]) {
    return !!data[0][header].alignRight;
  }

  return false;
};

const prepareSortFilterMapper = (data) => {
  const filterMapper = [];
  if (data[0])
    for (let key in data[0]) {
      if (data[0][key].sortable) {
        filterMapper.push([key, data[0][key].id]);
      }
    }
  return filterMapper;
};

const dataForSO = (data) => {
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'order_date',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Sales Order No.': {
      id: 'sales_order_no',
      getValue: function () {
        return myData[this.id];
      },
    },

    Reference: {
      id: 'reference',
      getValue: function () {
        return myData[this.id];
      },
    },
    Amount: {
      id: 'total',
      getValue: function () {
        return myData[this.id];
      },
    },
    Status: {
      id: 'status',
      hideStatusColor: true,
      getValue: function () {
        return (
          <span className={` float-right ${getStatusColor(myData[this.id])}`}>
            {myData[this.id]}
          </span>
        );
      },
    },
    // not displayable
    additional: {
      status: myData.status,
      linkTo: 'salesorders',
    },
  }));
};

const dataForPO = (data) => {
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'order_date',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Purchase Order No.': {
      id: 'purchase_order_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    Reference: {
      id: 'reference',
      getValue: function () {
        return myData[this.id];
      },
    },
    Amount: {
      id: 'total',
      getValue: function () {
        return myData[this.id];
      },
    },
    Status: {
      id: 'status',
      hideStatusColor: true,
      getValue: function () {
        return (
          <span className={` float-right  ${getStatusColor(myData[this.id])}`}>
            {myData[this.id]}
          </span>
        );
      },
    },
    // not displayable
    additional: {
      status: myData.status,
      linkTo: 'purchaseorders',
    },
  }));
};

const dataForPR = (data) => {
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'receive_date',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Receive No.': {
      id: 'receive_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Purchase Order No.': {
      id: 'purchase_order_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    Quantity: {
      id: 'received_quantity',
      getValue: function () {
        return myData[this.id];
      },
    },
    // not displayable
    additional: {
      status: myData.status,
    },
  }));
};

const dataForBills = (data) => {
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'bill_date',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Bill No.': {
      id: 'bill_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    Due: {
      id: 'balance_due',
      getValue: function () {
        return myData[this.id];
      },
    },
    Amount: {
      id: 'total',
      getValue: function () {
        return myData[this.id];
      },
    },
    Status: {
      id: 'status',
      hideStatusColor: true,
      getValue: function () {
        return (
          <span className={` float-right  ${getStatusColor(myData[this.id])}`}>
            {myData[this.id]}
          </span>
        );
      },
    },
    // not displayable
    additional: {
      status: myData.status,
      linkTo: 'bills',
    },
  }));
};

const dataForPaymentsMade = (data) => {
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'payment_date',
      getValue: function () {
        return myData[this.id];
      },
    },
    Reference: {
      id: 'reference',
      getValue: function () {
        return myData[this.id];
      },
    },
    Amount: {
      id: 'amount',
      getValue: function () {
        return myData[this.id];
      },
    },
    Type: {
      id: 'payment_mode',
      hideStatusColor: true,
      getValue: function () {
        return (
          <span className={` float-right  ${getStatusColor(myData[this.id])}`}>
            {myData[this.id]}
          </span>
        );
      },
    },
    // not displayable
    additional: {
      status: myData.status,
    },
  }));
};

const dataforItemAdjustments = (data) => {
  // console.log({data})
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'created_at',
      getValue: function () {
        return myData[this.id];
      },
    },
    Type: {
      id: 'type',
      getValue: function () {
        return myData.item_adjustment[this.id];
      },
    },
    Reason: {
      id: 'reason',
      getValue: function () {
        return (
          <div className="transaction-table-reason">
            {myData.item_adjustment[this.id]}
          </div>
        );
      },
    },
    'Adjusted Qty': {
      id: 'amount',
      getValue: function () {
        return myData[this.id];
      },
      isHeaderRightAlign: true,
    },
    // 'Type': {
    //   id: 'payment_mode',
    //   hideStatusColor: true,
    //   getValue: function () { return <span className={`${getStatusColor(myData[this.id])}`}>{myData[this.id]}</span>  }
    // },
    // not displayable

    '': {
      id: 'description',
      getValue: function () {
        return (
          <div className="transaction_description">
            {myData.item_adjustment && myData.item_adjustment.description && (
              <span className="notes_hover_adjustment">
                <NotesIcon className="table_icon" />
                <div className="notes_hover_adjustment_data">
                  <h2 className="float-left w-100">Description</h2>
                  {myData.item_adjustment.description}
                </div>
              </span>
            )}
          </div>
        );
      },
    },
    additional: {
      status: myData.status,
    },
  }));
};

const dataForInvoices = (data) => {
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'invoice_date',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Invoice No.': {
      id: 'invoice_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Payment Due': {
      id: 'payment_due',
      getValue: function () {
        return myData[this.id];
      },
    },
    Amount: {
      id: 'total',
      getValue: function () {
        return myData[this.id];
      },
    },
    Status: {
      id: 'status',
      hideStatusColor: true,
      getValue: function () {
        return (
          <span className={` float-right ${getStatusColor(myData[this.id])}`}>
            {myData[this.id]}
          </span>
        );
      },
    },
    // not displayable
    additional: {
      status: myData.status,
      linkTo: 'invoices',
    },
  }));
};

const dataForPackages = (data) => {
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'package_date',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Package No.': {
      id: 'package_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    Status: {
      id: 'status',
      hideStatusColor: true,
      getValue: function () {
        return (
          <span className={` float-right  ${getStatusColor(myData[this.id])}`}>
            {myData[this.id]}
          </span>
        );
      },
    },
    // not displayable
    additional: {
      status: myData.status,
      linkTo: 'packages',
    },
  }));
};

const dataForPaymentReceive = (data) => {
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'payment_date',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Payment No': {
      id: 'payment_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Invoice No': {
      id: 'invoice_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    Reference: {
      id: 'reference',
      getValue: function () {
        return myData[this.id];
      },
    },
    Amount: {
      id: 'amount',
      getValue: function () {
        return myData[this.id];
      },
    },
    Type: {
      id: 'payment_mode',
      hideStatusColor: true,
      getValue: function () {
        return (
          <span className={` float-right  ${getStatusColor(myData[this.id])}`}>
            {myData[this.id]}
          </span>
        );
      },
    },
    // not displayable
    additional: {
      status: myData.status,
    },
  }));
};

const dataForSalesReturn = (data) => {
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'sales_return_date',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Sales Return': {
      id: 'sales_return_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Add to Stock': {
      id: 'back_stock',
      getValue: function () {
        return myData[this.id];
      },
    },
    Quantity: {
      id: 'return_quantity',
      getValue: function () {
        return myData[this.id];
      },
    },
    Status: {
      id: 'status',
      hideStatusColor: true,
      getValue: function () {
        return (
          <span className={`  float-right  ${getStatusColor(myData[this.id])}`}>
            {myData[this.id]}
          </span>
        );
      },
    },
    // not displayable
    additional: {
      status: myData.status,
      linkTo: 'salesreturns',
    },
  }));
};

const dataForCreditNote = (data) => {
  return data.map((myData) => ({
    id: myData.id,
    Date: {
      id: 'credit_note_date',
      getValue: function () {
        return myData[this.id];
      },
    },
    'Credit Note': {
      id: 'credit_note_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    Invoice: {
      id: 'invoice_no',
      getValue: function () {
        return myData[this.id];
      },
    },
    Credit: {
      id: 'issued_credits',
      getValue: function () {
        return myData[this.id];
      },
    },
    Status: {
      id: 'status',
      hideStatusColor: true,
      getValue: function () {
        return (
          <span className={` float-right ${getStatusColor(myData[this.id])}`}>
            {myData[this.id] === 'partially applied'
              ? 'PRTL Applied'
              : myData[this.id]}
          </span>
        );
      },
    },
    // not displayable
    additional: {
      status: myData.status,
      linkTo: 'creditnotes',
    },
  }));
};

const prepareDataForTable = (data, value) => {
  if (value === 'sales_orders') return dataForSO(data);
  else if (value === 'invoices') return dataForInvoices(data);
  else if (value === 'packages') return dataForPackages(data);
  else if (value === 'payments_received') return dataForPaymentReceive(data);
  else if (value === 'sales_return') return dataForSalesReturn(data);
  else if (value === 'credit_notes') return dataForCreditNote(data);
  else if (value === 'purchase_orders') return dataForPO(data);
  else if (value === 'purchase_receives') return dataForPR(data);
  else if (value === 'bills') return dataForBills(data);
  else if (value === 'payments_made') return dataForPaymentsMade(data);
  else if (value === 'item_adjustments') return dataforItemAdjustments(data);
};

const ListTable = ({
  data = [],
  selectedData = [],
  // rowOptions = [],
  sortFilter = '',
  orderBy = 'desc',
  // preference = false,
  // preferenceLink = '',
  hasCheckbox = true,
  // showSettingsIcon,
  inModal = false,
  loading,
  isBlur = false,
  generateRoute = () => {},
  // handleCheckAll = () => {},
  // handleCheckSingle = () => {},
  // moduleName = '',
  value,
  // contactId,
}) => {
  const [dataForTable, setData] = useState();
  const { id = null } = useParams();
  useEffect(() => {
    setData(prepareDataForTable(data, value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading === true) return <Loader />;
  if (!dataForTable || dataForTable.length === 0) return <EmptyTable inModal />;
  const tableRef = React.createRef();
  const tableHeaders = headers(dataForTable);
  const filters = prepareSortFilterMapper(dataForTable);
  const hasData = !!dataForTable.length;

  // console.log({ id });

  return (
    <div
      className={`list-table--wrapper ${
        id ? 'customized-tbl' : 'no-customized-tbl'
      }`}
    >
      <div
        className={`transaction_table ${
          value === 'item_adjustments' ? 'min_height uiux_all_items ' : ''
        }`}
      >
        <table
          className={`list-table linked-row ${!hasData ? 'hidden' : ''}`}
          ref={tableRef}
        >
          <thead className="tabs-table">
            <tr>
              {tableHeaders.map((header) =>
                renderHeader(
                  header,
                  filters,
                  sortFilter,
                  orderBy,
                  generateRoute,
                  isHeaderRightAlign(header, dataForTable)
                )
              )}
            </tr>
          </thead>
          <tbody>
            {dataForTable.map((dataSingle, dataSingleIndex) => (
              <tr
                key={dataSingle.id}
                className={`table-row ${
                  dataSingle.hasOwnProperty('additional') &&
                  dataSingle.additional.selectedItemRow === true
                    ? 'selected'
                    : ''
                } ${selectedData.includes(dataSingle.id) ? 'selected' : ''} ${
                  isBlur && !dataSingle.additional[isBlur]
                    ? 'inactive'
                    : 'active'
                } ${dataSingleIndex % 2 === 1 ? 'grey-background' : ''}`}
              >
                {spreadValues(dataSingle, tableHeaders, hasCheckbox).map(
                  (value, index) => (
                    <td key={index}>
                      {dataSingle.additional.linkTo ? (
                        inModal ? (
                          <span className="list-table--link">{value}</span>
                        ) : (
                          <Link
                            to={`/${dataSingle.additional.linkTo}/${dataSingle.id}`}
                            className="list-table--link"
                          >
                            <span>{value}</span>
                          </Link>
                        )
                      ) : (
                        <div className="list-table--link">
                          <span>{value}</span>
                        </div>
                      )}
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListTable;
