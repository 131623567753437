import React, { Fragment } from 'react';
import PrimaryWarehouseIcon from '../../../../Assets/General/PrimaryWarehouseIcon';

const OpenStockTable = ({ list }) => {
  const renderTable = () => {
    return (
      <table className="table sub_tabs_transaction_main report--table float-left w-100 inventory_listing_Table_inner stock_distribution_table">
        <thead className="table-header hassan">
          <tr className="table--row">
            <th>Item</th>
            <th>SKU</th>
            <th>Warehouse</th>
            <th className="right-align">Opening Stock</th>
            <th className="right-align">Opening Stock Value</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, index1) => {
            return (
              <Fragment key={index1}>
                {item.inventory_type === 'inventory' ? (
                  item.stock.map((itemDetail, index) => {
                    return (
                      <tr>
                        {index === 0 && (
                          <td
                            rowSpan={item.stock.length ? item.stock.length : ''}
                          >
                            {item.name}
                          </td>
                        )}

                        {index === 0 && (
                          <td
                            rowSpan={item.stock.length ? item.stock.length : ''}
                          >
                            {item.sku}
                          </td>
                        )}

                        <td className="warehouse_main_set">
                          <div className="warehouse_name">
                            <span>{itemDetail.warehouse_name}</span>
                            {itemDetail.warehouse_primary === 'true' && (
                              <span
                                className="current-warehouse--current"
                                style={{ float: 'right', marginLeft: '0px' }}
                              >
                                <span
                                  className="icon_div"
                                  style={{ cursor: 'pointer' }}
                                >
                                  <PrimaryWarehouseIcon
                                    className="open-stock-icon"
                                    style={{ marginRight: '1.7rem' }}
                                  />
                                  <div className="tooltip_tooltip">
                                    Primary Warehouse
                                  </div>
                                </span>
                              </span>
                            )}
                            {itemDetail.warehouse_active === '0' &&
                              itemDetail.warehouse_primary !== 'true' && (
                                <span
                                  style={{
                                    padding: '2px 5px',
                                    marginLeft: '5px',
                                    border: '1px solid #ddd',
                                    backgroundColor: '#eee',
                                  }}
                                >
                                  Inactive
                                </span>
                              )}
                          </div>
                        </td>

                        <td className="right-align">
                          {itemDetail.open_quantity}
                        </td>
                        <td className="right-align">
                          {itemDetail.open_stock_value}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.sku}</td>
                    <td colSpan={3} className="text-center bold">
                      Non Inventory Product
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };
  return <div className="reports--body">{renderTable()}</div>;
};

export default OpenStockTable;
