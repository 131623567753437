import Axios from 'axios';
import {
  validToken,
  getOrganization,
  getRefreshToken,
  setToken,
} from './AuthHelper';
import { restPath, restSeebizPath } from './UrlHelper';

const requestWithToken = Axios.create();
requestWithToken.interceptors.response.use(
  function (response) {
    const refreshedToken = response.headers.authorization;
    if (refreshedToken) {
      setToken(refreshedToken);
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export function restRequest(method, path, data = {}) {
  const headers = {
    'Cache-Control': 'no-cache',
    Expires: '-1',
    Pragma: 'no-cache',
    'Cache-Control': 'max-age=0',
    Expires: 'Tue, 01 Jan 1980 11:00:00 GMT',
    authorization: validToken(),
    refresh_token: getRefreshToken(),
  };
  if (getOrganization()) {
    headers['organization'] = getOrganization();
  }
  return requestWithToken({
    method: method,
    url: restPath(path),
    data: data,
    headers: {
      ...headers,
    },
  }).then((res) => {
    //console.log('err', res);
    if (
      res.status &&
      res.status === 202 &&
      res.data.message &&
      res.data.message === 'Business access denied'
    ) {
      sessionStorage.setItem('OrganizationReadOnly', true);
      window.location.href = '/packagesplan';
      //console.log('err', res);
      return {};
    }
    return res.data;
  });
}

export function publicRequest(method, path, data = {}) {
  return Axios({
    method: method,
    url: restPath(path),
    data: data,
  }).then((res) => {
    return res.data;
  });
}

export function publicSeebizRequest(method, path, data = {}) {
  return Axios({
    method: method,
    url: restSeebizPath(path),
    data: data,
  }).then((res) => {
    return res.data;
  });
}

export function imagePathS3(imagePath, type = 'all') {
  if (!imagePath) {
    if (type === 'user') {
      return `${process.env.REACT_APP_AMAZON_S3}/media/default-user-image-placeholder.svg`;
    } else {
      return `${process.env.REACT_APP_AMAZON_S3}/media/image-placeholder.svg`;
    }
  }
  return `${process.env.REACT_APP_AMAZON_S3}/${imagePath}`;
}

export function imagePath(imagePath, type = 'all') {
  if (imagePath) {
    if (
      imagePath.match(
        /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i
      )
    )
      return imagePath;
  }

  return imagePathS3(imagePath, type);
}
