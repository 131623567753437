export const reportTitles = {
  sales: 'Sales Order Report',
  salesReturn: 'Sales Return Report',
  purchase: 'Purchase Order Report',
  invoice: 'Invoice Report',
  invoiceBycustomer: 'Invoice By Customer',
  paymentsReceived: 'Payments Received Report',
  packing: 'Packaging Report',
  receive: 'Receive Report',
  bill: 'Bill Report',
  billByvendor: 'Bill By Vendor Report',
  CreditnoteReport: 'Credit Note Report',
  CreditnotebyCustomerReport: 'Credit Note By Customer ',
  paymentsReceivedbycustomer: 'Payments Received By Customer',
  RefundreportbyCustomer: 'Refund Report By Customer',
  PaymentmadereportbyVendor: 'Payment Made Report By Vendor',
  purchaseByvendor: 'Purchase Order By Vendor',
  SaleOrderbycustomer: 'Sale Order By Customer',
  paymentsMade: 'Payments Made Report',
  inventoryDetails: 'Inventory Details Report',
  warehouse: 'Warehouse Report',
  committedstock: 'Committed Stock Details',
  productsales: 'Product Sales Report',
  activepurchaseorders: 'Active Purchase Orders Report',
  fifo: 'FIFO Cost Lot Tracking Report',
  inventoryValuation: 'Inventory Valuation Summary Report',
  taxreport: 'Tax Report',
  refundReport: 'Refund Report',
  customer: 'Sales by Customer',
  salesbyitem: 'Sales by Item',
  salesbysalesperson: 'Sales by Sales Person',
  orderfulfillmentbyitem: 'Order fulfillment by Item',
};
