import React, { Component } from 'react';

import SearchableDropdown from './../SearchableDropdown/SearchableDropdown';

import './PageViewCount.css';


class PageViewCount extends Component {
  state = {
    viewPerPage: this.props.viewPerPage,
    dropdownView: 'up'
  }

  static getDerivedStateFromProps( nextProps, prevState ) {
    if (nextProps.viewPerPage !== prevState.viewPerPage) {
      return {
        viewPerPage: nextProps.viewPerPage
      }
    }
    // No state update necessary
    return null
  }

  determineDropdown = (event) => {
    let screenHeight = event.view.innerHeight
    let verticalPositionBelow = this.pageViewCount.getBoundingClientRect().y
    let offsetHeight = 55 // height between content and bottom of page
    let dropdownHeight = 180 // height of dropdown options
    let minOffset = screenHeight - offsetHeight - dropdownHeight
    if (minOffset > verticalPositionBelow) {
      this.setState({ dropdownView: 'down' })
    }
    else {
      this.setState({ dropdownView: 'up' })
    }
  }

  render() {
    let {viewPerPage, dropdownView} = this.state
    return (
      <div className="page-view-count page-count-header" ref={el => (this.pageViewCount = el)} onFocus={(event) => this.determineDropdown(event)}>
        <div className="page-view-dropdown-container">
          <SearchableDropdown
            size='xs'
            className={`pagination-route page-view-dropdown-body ${dropdownView}`}
            state={viewPerPage}
            options={[10, 20, 40, 60, 80, 100]}
            value="value"
            label="label"
            handleChange={(option) => this.props.handleViewCount(option.value)}
            searchable={false}
            clearable={false}
          />
        </div>
      </div>
    )
  }
}

export default PageViewCount
