import React, { Component, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { MdAdd, MdOutlineHelp, IoSettingsOutline } from '../../Common/Icons';
import { GoAlertFill } from 'react-icons/go';

import SidePanel from '../SidePanel/SidePanel';
import SearchBar from '../SearchBar/SearchBar';

import {
  checkError,
  logout,
  removeTokenCookies,
} from '../../Helpers/AuthHelper';
import { restRequest } from '../../Helpers/RequestHelper';
import { imagePathS3 } from '../../Helpers/RequestHelper';
import { ssosignoutPath } from '../../Helpers/UrlHelper';
import { getCurrentOrganization } from '../../Helpers/CurrentSession';

import logo from '../../Assets/Img/logo.svg';
import orgImage from '../../Assets/Img/org-image.jpg';
import './Header.css';
import './../../Pages/Navigation/Navigation.css';
import QuickAdd from '../QuickAdd/QuickAdd';
import SettingsDropDown from '../SettingsDropDown/SettingsDropDown';
import HelpSection from '../HelpSection/HelpSection';
import {
  isAndroid,
  isTablet,
  isIOS,
  isWinPhone,
  isMobile,
} from 'mobile-device-detect';
import { getOldAcc } from '../../Helpers/GetOldAccount';
import { ImageUrl } from '../../Helpers/ImageUrl';
// import { ProgressBar } from 'react-bootstrap';

class Header extends Component {
  ssosignoutLink = ssosignoutPath();
  sidePanelFlag = false;
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: null,
      showSidePanel: false,
      showQuickAdd: false,
      showSettingsDropDown: false,
      taxcount: 0,
      deliverycount: 0,
      showHelpSection: false,
      organizations: [],
      planLimit: {},
      free_plan: false,
      loading: true,
      exportBtn: JSON.parse(localStorage.getItem('exportBtn')) || false,
      currentOrg: {
        logo: orgImage,
        name: null,
        email: null,
      },
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleResultOutsideClick);
    window.addEventListener('storage', this.handleStorageChange);
    window.addEventListener('storageUpdate', this.handleStorageChange); // Custom event

    window.addEventListener('resize', this.resize.bind(this));
    this.fetchFilteredData();
    this.fetchData();
    this.resize();

    this.handleStorageChange();
  }

  // Example of using it

  isMobileScreen = () =>
    isMobile || isAndroid || isTablet || isIOS || isWinPhone;

  resize() {
    this.setState({ screenWidth: window.innerWidth <= 760 });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
    window.removeEventListener('storage', this.handleStorageChange);
    window.removeEventListener('storageUpdate', this.handleStorageChange); // Clean up custom event listener
  }

  async fetchData() {
    this.setState({ loading: true });
    await restRequest('get', `organizations`)
      .then((res) => {
        let currentOrganization = res.organizations.find(
          (org) => org.organizations.id === getCurrentOrganization().id
        );
        this.setState({
          organizations: res.organizations,
          planLimit: res.plan_limit,
          loading: false,
          currentOrg: {
            logo:
              currentOrganization && currentOrganization.organizations.logo
                ? ImageUrl(currentOrganization.organizations.logo)
                : ImageUrl(orgImage)
                ? orgImage
                : imagePathS3(orgImage),
            name: currentOrganization && currentOrganization.organizations.name,
            email:
              currentOrganization &&
              currentOrganization.organizations.primary_contact_email
                ? currentOrganization.organizations.primary_contact_email
                : '',
          },
        });
        if (this.state.planLimit.hasOwnProperty('pkg_type')) {
          this.setState({
            free_plan: true,
            planLimit: { status: true },
          });
        }
      })
      .catch((error) => {
        checkError(error);
      });
  }
  handleStorageChange = () => {
    const exportBtnData = localStorage.getItem('exportBtn');

    if (exportBtnData !== null) {
      this.setState({ exportBtn: exportBtnData });
    } else {
      this.setState({ exportBtn: false });
    }
  };

  async fetchFilteredData() {
    this.setState({ reportLoading: true });
    // let { taxcount, deliverycount } = this.state;

    return await restRequest('get', `accounts/system-settings`)
      .then((res) => {
        this.setState({
          taxcount: res.taxes,
          deliverycount: res.deliveryMethods,
          reportLoading: false,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ reportLoading: false });
      });
  }

  homeRoute() {
    return process.env.REACT_APP_HOME;
  }
  handleResultOutsideClick = (event) => {
    if (
      this.sidePanelElement &&
      !this.sidePanelElement.contains(event.target) &&
      this.organizationImageButton &&
      !this.organizationImageButton.contains(event.target)
    ) {
      this.setState({
        showSidePanel: false,
      });
    }

    if (
      this.quickSettings &&
      !this.quickSettings.contains(event.target) && //did this because the above condition returned undefined for settings button, So handled it in a different way.
      this.settingButtonRef &&
      !this.settingButtonRef.contains(event.target)
    ) {
      this.setState({
        showSettingsDropDown: false,
      });
    }

    if (
      this.quickElement &&
      !this.quickElement.contains(event.target) && //did this because the above condition returned undefined for settings button, So handled it in a different way. The quick add button closes now when clicked outsite.
      this.quickAddButtonElement &&
      !this.quickAddButtonElement.contains(event.target)
    ) {
      this.setState({
        showQuickAdd: false,
      });
    }

    if (
      this.quickHelp &&
      !this.quickHelp.contains(event.target) && //did this because the above condition returned undefined for settings button, So handled it in a different way. The quick add button closes now when clicked outsite.
      this.helpButtonRef &&
      !this.helpButtonRef.contains(event.target)
    ) {
      this.setState({
        showHelpSection: false,
      });
    }
  };

  accountRoute() {
    return `/myaccount`;
  }

  handleLogout = () => {
    const sso_connected =
      process.env.REACT_APP_SSO_ENABLED === 'false' ? false : true;
    this.setState({
      loading: true,
    });
    // restRequest('get', 'ssoSettings')
    //   .then((res) => {
    if (sso_connected) {
      //removeTokenCookies();
      window.location.href = this.ssosignoutLink;
      this.setState({ loading: false });
    } else {
      logout();
      //this.props.history.push('/');
    }
    // })
    // .catch((error) => {
    //   logout();
    //   this.props.history.push('/');
    //   this.setState({ loading: false });
    // });
    // logout();
    // this.props.history.push('/');
  };

  render() {
    const { exportBtn } = this.state;
    const { name, email } = this.props.currentUser;
    const { ssoConnected } = this.props;
    function closeMe() {
      this.setState({
        showSidePanel: false,
        showQuickAdd: false,
        showSettingsDropDown: false,
        showHelpSection: false,
      });
    }
    function hideQuickAdd() {
      setTimeout(() => {
        this.setState({
          showQuickAdd: false,
        });
      }, 0);
    }
    return (
      <>
        {/* <div>
          {JSON.parse(exportBtn) ? (
            <ProgressBar
              now={60}
              variant="info"
              // label={`${60}%`}
              style={{ height: '4px' }}
            />
          ) : null}
        </div> */}
        <div
          className="header header-mobile"
          onClick={
            this.isMobileScreen() ? this.props.toggleNavigationHeader : null
          }
        >
          <div className={classNames('header-left')}>
            <Link
              to="/"
              className="header-logo"
              onClick={() => sessionStorage.setItem('expandSettings', false)}
            >
              {' '}
              {/*collapse settings tab on page reload*/}
              <img src={logo} alt="SeeBiz IMS" />
            </Link>
            <div
              className="header-icon-set d-flex align-center"
              title="Quick Actions"
              ref={(element) => {
                this.quickAddButtonElement = element;
              }}
            >
              <MdAdd
                style={{ marginLeft: '73px' }}
                title="Quick Actions"
                className={`material-icon pointer custom-transition ${
                  this.state.showQuickAdd ? 'add-transform' : ''
                }`}
                onClick={() => {
                  this.setState({
                    showQuickAdd: !this.state.showQuickAdd,
                  });
                  this.setState({
                    showSidePanel: this.state.showSidePanel ? false : false,
                  });
                  if (this.props.viewNavigation && this.isMobileScreen())
                    this.props.toggleNavigation();
                }}
              />
            </div>
          </div>

          <div className="header-center header-responsive-main">
            <SearchBar />
          </div>

          {this.state.planLimit.status === false &&
            this.state.planLimit.expired === 'expired' && (
              <div className="header-center">
                <p
                  className="alert-exceed-packageplan"
                  style={{ marginBottom: '0', marginLeft: '10px' }}
                >
                  {' '}
                  Plan Expired.
                  <Link to="/packagesplan"> Upgrade</Link>{' '}
                </p>
              </div>
            )}

          {this.state.planLimit.status === false &&
            this.state.planLimit.expired === 'expiring_soon' && (
              <div className="header-center">
                <p
                  className="alert-exceed-packageplan"
                  style={{ marginBottom: '0', marginLeft: '10px' }}
                >
                  {' '}
                  Plan Expiring Soon.{' '}
                </p>
              </div>
            )}

          {this.state.planLimit.status === false &&
            this.state.planLimit.limit_exceeded === true && (
              <div className="header-center">
                <p
                  className="alert-exceed-packageplan"
                  style={{ marginBottom: '0', marginLeft: '10px' }}
                >
                  {' '}
                  Plan limit Exceeded.
                  <Link to="/packagesplan"> Upgrade</Link>{' '}
                </p>
              </div>
            )}

          {this.state.free_plan === true && (
            <div className="header-center">
              <p
                className="alert-exceed-packageplan"
                style={{ marginBottom: '0', marginLeft: '10px' }}
              >
                {' '}
                Free Plan Activated.
                <Link to="/packagesplan"> Upgrade</Link>{' '}
              </p>
            </div>
          )}
          <div className="header-center">
            {JSON.parse(exportBtn) && (
              <p
                // className="alert-exceed-packageplan"
                style={{ marginBottom: '0', marginLeft: '25px' }}
              >
                <strong>Downloading In Progress...</strong>
              </p>
            )}
          </div>
          <div className="header-right header-responsive-right">
            <GetOldAcc
              onClick={() => {
                localStorage.setItem('skipBtnoldAccount', false);
                this.props.history.push('/');
                if (this.props.viewNavigation && this.isMobileScreen())
                  this.props.toggleNavigation();
              }}
            />

            <div className="header-user">
              <strong>Hello,</strong>
              <span>{name ? name : email}</span>
            </div>
            <div
              id="settings_dropdown"
              title="Settings"
              ref={(element) => {
                this.settingButtonRef = element;
              }}
            >
              <IoSettingsOutline
                id="settings_icon"
                title="Settings"
                className={`material-icon pointer blue-icon ${
                  this.state.showSettingsDropDown ? 'settings-transform' : ''
                }`}
                onClick={() => {
                  this.setState({
                    showSettingsDropDown: !this.state.showSettingsDropDown,
                  });
                  this.setState({
                    showSidePanel: this.state.showSidePanel ? false : false,
                  });
                  if (this.props.viewNavigation && this.isMobileScreen())
                    this.props.toggleNavigation();
                }}
              />
            </div>

            <div
              id="help_section"
              title="Help"
              ref={(element) => {
                this.helpButtonRef = element;
              }}
            >
              <MdOutlineHelp
                id="help_icon"
                className="material-icon blue-icon"
                style={{ marginRight: '1.7rem' }}
                onClick={() => {
                  this.setState({
                    showHelpSection: !this.state.showHelpSection,
                  });
                  this.setState({
                    showSidePanel: this.state.showSidePanel ? false : false,
                  });
                  if (this.props.viewNavigation && this.isMobileScreen())
                    this.props.toggleNavigation();
                }}
              />
            </div>
            <div
              id="currentOrg_name"
              title={this.state.currentOrg.name}
              className="user-avatar pointer"
              onClick={() => {
                this.setState({
                  showSidePanel: this.state.showSidePanel ? false : true,
                });
                this.setState({
                  showSettingsDropDown: this.state.showSettingsDropDown
                    ? false
                    : false,
                });
                if (this.props.viewNavigation && this.isMobileScreen())
                  this.props.toggleNavigation();
                if (this.sidePanelFlag !== true) {
                  this.sidePanelFlag = true;
                }
              }}
            >
              <div
                id="currentOrg_circle"
                className="image_circle_dp"
                ref={(element) => {
                  this.organizationImageButton = element;
                }}
              >
                <img
                  id="currentOrg_logo"
                  src={this.state.currentOrg.logo}
                  alt="OrganiztionImg"
                />
              </div>
            </div>

            {this.sidePanelFlag ? (
              <div
                ref={(element) => {
                  this.sidePanelElement = element;
                }}
              >
                <SidePanel
                  showSidePanel={this.state.showSidePanel}
                  closeMe={closeMe.bind(this)}
                  handleLogout={this.handleLogout}
                  handleToast={this.props.handleToast}
                  history={this.props.history}
                  ssoConnected={ssoConnected}
                />
              </div>
            ) : null}

            <div
              ref={(element) => {
                this.quickElement = element;
              }}
            >
              {this.state.showQuickAdd && (
                <QuickAdd hideQuickAdd={hideQuickAdd.bind(this)} />
              )}
            </div>

            <div
              className={`help_section help-mob ${
                this.state.showHelpSection ? 'show' : ''
              }`}
              ref={(element) => {
                this.quickHelp = element;
              }}
            >
              {this.state.showHelpSection && (
                <HelpSection closeMe={closeMe.bind(this)} />
              )}
            </div>

            <div
              ref={(element) => {
                this.quickSettings = element;
              }}
            >
              {/*this.state.showSettingsDropDown && <SettingsDropDown value={this.state} closeMe={closeMe.bind(this)} />*/}
              {this.state.showSettingsDropDown && (
                <SettingsDropDown
                  closeMe={closeMe.bind(this)}
                  value={this.state}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Header);

const GetOldAcc = ({ onClick }) => {
  const [skipButton, setSkipButton] = useState({
    skip: null,
    organization: null,
  });
  const skipBtnValue = JSON.parse(localStorage.getItem('skipBtnoldAccount'));
  const organizationValue = JSON.parse(
    localStorage.getItem('currentOrganization')
  )?.mapped_account_status;
  useEffect(() => {
    setSkipButton({
      skip: skipBtnValue,
      organization: organizationValue,
    });
  }, [skipBtnValue]);
  return (
    <>
      {skipButton.skip && !skipButton.organization && (
        <div className="alert_icon" onClick={onClick}>
          <GoAlertFill
            id="settings_icon"
            style={{ color: 'red' }}
            title="Kindly map your old COA with new COA"
            className="material-icon pointer"
          />
          <span>Map Account</span>
        </div>
      )}
    </>
  );
};
