import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import { startOfDay, endOfDay } from 'date-fns';
import moment from 'moment';

import { getOrganizationDate } from '../../../Helpers/Date/OrganizationDate';

import Loader from '../../../Components/Loader/Loader';

import { NewReportIcon } from '../../../Assets/Navigation/NavigationIcons';
import './Report.css';
import { restRequest } from '../../../Helpers/RequestHelper';
import {checkError, setDeploymentTime} from '../../../Helpers/AuthHelper';
import useMobileDetection from '../../../Hooks/MobileDetect';

const ReportHOC = ({
  title,
  filterKeys, // array containing list of filters
  filterValidation,
}) => {
  const [hasMobile = false] = useMobileDetection();

  return (WrappedComponent) => {
    return class ReportHOC extends Component {
      state = {
        queryString: this.props.location.search,
        filters: {
          date: moment(getOrganizationDate()),
          dateRange: {
            selection: {
              startDate: moment(getOrganizationDate()).subtract(7, 'days'),
              endDate: moment(endOfDay(getOrganizationDate())),
              key: 'selection',
            },
          },
          warehouseID: null,
          contactID: null,
          itemID: null,
          status: 'all',
          stockTracking: 'shipments_receives',
          items: [],
          sortBy: 'created_at',
          orderBy: 'desc',
          page: 1,
          viewPerPage: 20,
        },
        loadingHoc: true,
      };

      updateFilters(query) {
        let { filters } = this.state;
        let queryKeys = Object.keys(query);
        let updatedQueryFilters = { ...filters };

        for (let key of filterKeys) {
          // update filters passed into HOC
          if (queryKeys.includes(key)) {
            switch (key) {
              case 'date':
                updatedQueryFilters[key] = moment(endOfDay(query[key]));
                break;
              case 'startDate':
                updatedQueryFilters['dateRange']['selection'][key] = moment(
                  startOfDay(query[key])
                );
                break;
              case 'endDate':
                updatedQueryFilters['dateRange']['selection'][key] = moment(
                  endOfDay(query[key])
                );
                break;
              case 'items':
                if (updatedQueryFilters[key].length < 2) {
                  //updatedQueryFilters[key] = Array.from(query[key])
                  updatedQueryFilters[key] = query[key];
                } else {
                  updatedQueryFilters[key] = Array.from(query[key]);
                }
                break;
              case 'warehouseID':
              case 'itemID':
              case 'page':
              case 'viewPerPage':
                updatedQueryFilters[key] = parseInt(query[key]) || filters[key];
                break;
              case 'status':
              case 'stockTracking':
                if (filterValidation[key].includes(query[key])) {
                  updatedQueryFilters[key] = query[key];
                } else {
                  updatedQueryFilters[key] = filterValidation[key][0];
                }
                break;
              default:
                updatedQueryFilters[key] = query[key];
                break;
            }
          }
        }
        return updatedQueryFilters;
      }

      componentDidMount() {
        const query = queryString.parse(this.state.queryString); // parsed query into object
        this.setState({ filters: this.updateFilters(query) }, () => {
          this.setState({ loadingHoc: false });
        });
        this.fetchDeployment();
      }

      async fetchDeployment() {
        await restRequest('get', `deploymentTime`)
          .then((res) => {
            setDeploymentTime(res);
          })
          .catch((error) => {
            checkError(error, this.props.handleToast);
            this.setState({ loading: false });
          });
      }

      handleDateChange = (date) => {
        let updatedDate = moment(endOfDay(date));
        this.setState((state) => ({
          filters: {
            ...state.filters,
            date: updatedDate,
          },
        }));
      };

      handleDateRangeChange = (payload) => {
        this.setState((state) => ({
          filters: {
            ...state.filters,
            dateRange: {
              selection: {
                startDate: moment(payload.selection.startDate),
                endDate: moment(endOfDay(payload.selection.endDate)),
                key: 'selection',
              },
            },
          },
        }));
      };

      handleWarehouseSelect = (option) => {
        let warehouseID = option !== null ? option.id : 'all';
        this.setState((state) => ({
          filters: { ...state.filters, warehouseID },
        }));
      };

      handleCustomerSelect = (option) => {
        let contactID = option !== null ? option.value : 'all';
        this.setState((state) => ({
          filters: { ...state.filters, contactID },
        }));
      };

      handleDropdownChange = (id, stateName) => {
        this.setState((state) => ({
          filters: { ...state.filters, [stateName]: id },
        }));
      };

      handleSingleItemSelect = (option) => {
        let itemID = option !== null ? option.id : null;
        this.setState((state) => ({ filters: { ...state.filters, itemID } }));
      };

      handleItemsSelect = (options) => {
        if (options === null) {
          this.setState({
            filters: { ...this.state.filters, items: [] },
          });
        } else {
          let itemsIdList = options.map((option) => option.id);
          this.setState({
            filters: { ...this.state.filters, items: itemsIdList },
          });
        }
      };

      handleSorting = (name) => {
        let alternateOrder = (order) => {
          if (order === 'asc') return 'desc';
          else if (order === 'desc') return 'asc';
        };
        let { sortBy, orderBy } = this.state.filters;
        let updatedFilters = {
          ...this.state.filters,
          sortBy: name,
          orderBy: sortBy === name ? alternateOrder(orderBy) : 'asc',
        };
        this.setState({ filters: updatedFilters });
      };

      handlePaginate = (value, state) => {
        this.setState({
          filters: { ...this.state.filters, [state]: value },
        });
      };

      headerTitle = () => {
        return (
          <span className="section-header--title">
            <span className="uiux-reports-title">
              <NewReportIcon />
            </span>
            <span>
              {' '}
              <span className="uiux-reports-title">Reports / </span>
              <span className="bold">{title}</span>
            </span>
          </span>
        );
      };

      render() {
        if (this.state.loadingHoc) return <Loader />;
        else {
          let { filters } = this.state;
          return (
            <Fragment>
              <WrappedComponent
                {...this.props}
                filters={{ ...filters }}
                handleDateChange={this.handleDateChange}
                handleDateRangeChange={this.handleDateRangeChange}
                handleWarehouseSelect={this.handleWarehouseSelect}
                handleCustomerSelect={this.handleCustomerSelect}
                handleSingleItemSelect={this.handleSingleItemSelect}
                orderBy={filters.orderBy}
                sortBy={filters.sortBy}
                handleDropdownChange={this.handleDropdownChange}
                handleItemsSelect={this.handleItemsSelect}
                handleSorting={this.handleSorting}
                handlePaginate={this.handlePaginate}
                headerTitle={this.headerTitle}
                hasMobile={hasMobile}
              />
            </Fragment>
          );
        }
      }
    };
  };
};

export default ReportHOC;
