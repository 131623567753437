import React, { Component, Fragment } from 'react';

import CustomModal from '../../../Components/CustomModal/CustomModal';
import CustomDatePicker from '../../../Components/CustomDatePicker/CustomDatePicker';
import Field from '../../../Components/Form/Field/Field';

import { SettingIcon } from '../../../Assets/Navigation/NavigationIcons';

class ReportFilters extends Component {
  state = {
    showFilterModal: false,
  };

  renderDatePicker() {
    return (
      <CustomDatePicker
        type="single"
        date={this.props.date}
        onChange={(date) => this.props.date.onChange(date)}
      />
    );
  }

  renderDateRangePicker() {
    let dateRange = this.props.dateRange.value;
    return (
      <CustomDatePicker
        type="range"
        ranges={dateRange}
        onChange={(selection) => this.props.dateRange.onChange(selection)}
      />
    );
  }

  renderWarehouseFilter() {
    return (
      <Field
        size="large"
        type="dropdown"
        name="Warehouse"
        value={this.props.warehouse.selected}
        options={this.props.warehouse.options}
        dropdownValue="id"
        dropdownLabel="name"
        handleChange={this.props.warehouse.onChange}
        searchable={false}
      />
    );
  }

  renderStatusFilter() {
    return (
      <Field
        size="large"
        type="dropdown"
        name="Status"
        value={this.props.status.selected}
        options={this.props.status.options}
        dropdownValue="id"
        dropdownLabel="label"
        handleChange={(option) =>
          this.props.status.onChange(option.id, 'status')
        }
        clearable={false}
      />
    );
  }

  renderStockTrackingFilter() {
    return (
      <Field
        size="large"
        type="dropdown"
        name="Mode of Stock Tracking"
        value={this.props.stockTracking.selected}
        options={this.props.stockTracking.options}
        dropdownValue="id"
        dropdownLabel="label"
        handleChange={(option) =>
          this.props.stockTracking.onChange(option.id, 'stockTracking')
        }
        clearable={false}
      />
    );
  }

  renderItemsFilter() {
    let items = this.props.items;
    return (
      <Field
        size="large"
        type="multiselect"
        name="Item Name"
        value={items.selected}
        options={items.options}
        valueKey="id"
        labelKey="name"
        additionalSearchFilter="sku"
        handleChange={items.onChange}
        noResultsText="No items left"
        removeSelected
      />
    );
  }

  renderSingleItemFilter() {
    let item = this.props.item;
    return (
      <Field
        size="large"
        type="dropdown"
        name="Item Name"
        value={item.selected}
        options={item.options}
        dropdownValue="id"
        dropdownLabel="name"
        additionalSearchFilter="sku"
        handleChange={item.onChange}
      />
    );
  }

  renderFilters() {
    let filters = [];
    for (let filter of this.props.filters) {
      switch (filter) {
        case 'warehouse':
          filters.push(this.renderWarehouseFilter());
          break;
        case 'status':
          filters.push(this.renderStatusFilter());
          break;
        case 'stockTracking':
          filters.push(this.renderStockTrackingFilter());
          break;
        case 'items':
          filters.push(this.renderItemsFilter());
          break;
        case 'item':
          filters.push(this.renderSingleItemFilter());
          break;
        default:
          return;
      }
    }
    return filters;
  }

  renderOtherFilters() {
    if (!!this.props.filters.length) {
      return (
        <button
          className="button report-filter--btn"
          onClick={() => this.setState({ showFilterModal: true })}
        >
          <SettingIcon /> <span>Customize Report</span>
        </button>
      );
    }
    return <div />;
  }

  renderResetFilter() {
    if (this.props.showResetButton) {
      if (!!this.props.filters.length) {
        return (
          <button
            className="button report-filter--btn"
            onClick={() => {
              if (this.props.filters.includes('items')) {
                this.props.items.selected = [];
                this.props.items.onChange(this.props.items.selected);
              }
              if (this.props.filters.includes('item')) {
                this.props.item.selected = [];
                this.props.item.onChange(this.props.item.selected);
              }
              if (this.props.filters.includes('warehouse')) {
                this.props.warehouse.selected = null;
                this.props.warehouse.onChange(this.props.warehouse.selected);
              }
              if (this.props.filters.includes('status')) {
                this.props.status.selected = 'all';
                this.props.status.onChange(
                  this.props.status.selected,
                  'status'
                );
              }
              if (this.props.filters.includes('stockTracking')) {
                this.props.stockTracking.selected = 'shipments_receives';
                this.props.stockTracking.onChange(
                  'shipments_receives',
                  'stockTracking'
                );
              }
            }}
          >
            <SettingIcon /> <span>Reset Filter</span>
          </button>
        );
      }
    }
    return <div />;
  }

  renderDateFilter() {
    if (this.props.date) {
      return this.renderDatePicker();
    } else if (this.props.dateRange) {
      return this.renderDateRangePicker();
    }
    return <div />;
  }

  renderFilterModal() {
    return (
      <CustomModal
        showModal={this.state.showFilterModal}
        title="Customize Report"
        onClose={() => this.setState({ showFilterModal: false })}
        renderActions={false}
      >
        <div className="layout--grid-container grid-1-1 report--filter-modal">
          {this.renderFilters().map((filter, index) => (
            <Fragment key={index}>{filter}</Fragment>
          ))}
        </div>
      </CustomModal>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderFilterModal()}
        <div className="reports--filter float-right">
          {this.renderDateFilter()}
          {this.renderOtherFilters()}
          {this.renderResetFilter()}
        </div>
      </Fragment>
    );
  }
}

export default ReportFilters;
