import React, { Component } from 'react'

import PageViewCount from './PageViewCount'
import PageNumber from './PageNumber'

import './Pagination.css'
import { withRouter } from 'react-router-dom'

class Pagination extends Component {
  state = {
    viewPerPage: this.props.viewPerPage,
    totalPage: this.props.totalPage,
    currentPage: this.props.currentPage,
  }

  static getDerivedStateFromProps( nextProps, prevState ) {
    if (nextProps.totalPage !== prevState.totalPage) {
      return {
        totalPage: nextProps.totalPage
      }
    }
    // No state update necessary
    return null
  }

  handleViewCount = (value) => {
    this.setState({
      viewPerPage: value
    }, () => {
      this.props.onViewPerPageChange(this.state.viewPerPage)
      this.handleSelectedPage(1)
    })
  }

  handleSelectedPage = (page) => {
    this.setState({
      currentPage: page
    }, () => this.props.onPageChange(this.state.currentPage))
  }

  previousPage = () => {
    this.setState(({currentPage}) => ({
      currentPage: currentPage !== 1 ? currentPage - 1 : currentPage
    }), () => this.props.onPageChange(this.state.currentPage))
    return this.props.history.push(this.props.url)
  }
  
  nextPage = () => {
    this.setState(({currentPage, totalPage}) => ({
      currentPage: currentPage !== totalPage ? currentPage + 1 : currentPage
    }), () => this.props.onPageChange(this.state.currentPage))
    return this.props.history.push(this.props.url)
  }

  render() {
    let {
      viewPerPage,
      totalPage,
      currentPage,
    } = this.state
    return (
      <div className="pagination-container pagination-route">
        <PageViewCount
          viewPerPage={viewPerPage}
          handleViewCount={this.handleViewCount}
        />
        <PageNumber
          totalPage={totalPage}
          route={this.props.url}
          currentPage={currentPage}
          handleSelectedPage={this.handleSelectedPage}
          nextPage={this.nextPage}
          previousPage={this.previousPage}
          history={this.props.history}
        />
      </div>
    )
  }
}


export default withRouter(Pagination)