import React, { Component } from 'react';
import queryString from 'query-string';
import _ from 'lodash';

import { restRequest } from '../../../Helpers/RequestHelper';

// import Pagination from '../../../Components/Pagination/Pagination'
import Layout, { Header } from '../../../Components/Layout/Layout'; //, Body

import ReportHeader from './ReportHeader';
import ReportFilters from './ReportFilters';
import ReportOrganization from './ReportOrganization';
import ReportTable from './ReportTable';
import ReportHOC from './ReportHOC';
import { reportTitles } from '../ReportsUtils';
import getDate from '../../../Helpers/Date/GetDate';
import { checkError } from '../../../Helpers/AuthHelper';

class RefundreportbyCustomer extends Component {
  state = {
    loading: true,
    reportLoading: false,
    warehouseList: [],
    currency: {},
    reportData: [],
    reportTotal: {},
    totalPage: 1,
  };

  async fetchData() {
    this.setState({ loading: true });

    // await this.fetchFilteredData();

    this.setState({ loading: false });
  }

  componentDidMount() {
    document.title = reportTitles.RefundreportbyCustomer;
    this.fetchData().then(() => this.setState({ loading: false }));
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.filters, this.props.filters)) {
      this.fetchFilteredData();
      this.props.history.push(this.generateURL());
    }
  }

  fetchFilteredData() {
    this.setState({ reportLoading: true });
    //let addAll = [{ id: 'all', name: 'All', is_active: 1 }];
    let {
      dateRange: {
        selection: { startDate, endDate },
      },
      //warehouseID,
      sortBy,
      orderBy,
      contactID,
      page,
      viewPerPage,
    } = this.props.filters;

    return restRequest(
      //&warehouse_id=${warehouseID || ''}
      'get',
      `report/refund?page=${page}` +
        `&starting_date=${startDate.format(`YYYY-MM-DD 00:00:00`)}` +
        `&ending_date=${endDate.format('YYYY-MM-DD 23:59:59')}&contact_id=${
          contactID || ''
        }` +
        `&warehouse_id=all&view=${viewPerPage}` +
        `&order_by=${orderBy}` +
        `&sort_by=${sortBy}`
    )
      .then((res) => {
        this.setState({
          reportData: res.data.data,
          reportTotal: {
            totalPaidAmount: `${
              res.organization.base_currency.symbol
            }${res.total_paid_amount.toFixed(2)}`,
          },
          totalPage: res.data.last_page,
          //warehouseList: addAll.concat(res.warehouse_list),
          reportLoading: false,
          currency: res.organization.base_currency,
        });
      })
      .catch((error) => {
        checkError(error, this.props.handleToast);
        this.setState({ reportLoading: false });
      });
  }

  generatePDFLink() {
    const {
      dateRange: {
        selection: { startDate, endDate },
      },
      //warehouseID,
      sortBy,
      orderBy,
    } = this.props.filters;
    //&warehouse_id=${warehouseID || ''}
    return (
      `report/refund/pdf?starting_date=${startDate.format(
        `YYYY-MM-DD 00:00:00`
      )}` +
      `&ending_date=${endDate.format('YYYY-MM-DD 23:59:59')}` +
      `&order_by=${orderBy}&sort_by=${sortBy}`
    );
  }

  generatePDFLinkForMobile() {
    const {
      dateRange: {
        selection: { startDate, endDate },
      },
      //warehouseID,
      sortBy,
      orderBy,
    } = this.props.filters;
    //&warehouse_id=${warehouseID || ''}
    return (
      `starting_date=${startDate.format(`YYYY-MM-DD 00:00:00`)}` +
      `&ending_date=${endDate.format('YYYY-MM-DD 23:59:59')}` +
      `&order_by=${orderBy}&sort_by=${sortBy}`
    );
  }

  generateURL() {
    let { dateRange, sortBy, orderBy, page, viewPerPage } = this.props.filters;
    let searchParams = {
      startDate: dateRange.selection.startDate.format('YYYY-MM-DD'),
      endDate: dateRange.selection.endDate.format('YYYY-MM-DD'),
      //warehouseID,
      sortBy,
      orderBy,
      page,
      viewPerPage,
    };
    return `${this.props.match.path}?${queryString.stringify(searchParams, {
      sort: false,
    })}`;
  }

  render() {
    let {
      warehouseList,
      reportData,
      reportTotal,
      reportLoading,
      totalPage,
      currency,
    } = this.state;
    let { filters } = this.props;

    let formattedReportData = reportData.map((row) => ({
      refund_date: getDate(row.refund_date),
      payment_no: `Payment # ${row.advance_payment.payment_no}`,
      display_name: row.customer.display_name,
      mode: row.mode,
      reference: row.reference,
      //note: row.note,
      amount: `${currency.symbol}${row.amount.toFixed(2)}`,
    }));

    //console.log("formattedReportData", formattedReportData)

    let tableHeaders = [
      'Date',
      'Payment No.', //i change Payment No. to Payment No for symmetry in Payment No and Invoice No,
      'Customer Name',
      'Payment Method',
      'Reference',
      //'Notes',
      'Amount',
    ];
    let tableData = [
      'refund_date',
      'payment_no',
      'display_name',
      'mode',
      'reference',
      //'note',
      'amount',
    ];
    let rightAlignColumns = [7];

    return (
      <div className="float-left w-100 inventory_reports_section refund_report">
        <Layout>
          <Header title={this.props.headerTitle()}>
            <ReportHeader
              pdfLink={this.generatePDFLink()}
              pdfMobileLink={this.generatePDFLinkForMobile()}
              from="refund_report"
              toast={this.props.handleToast}
              pdfName={'Refund Report'}
              showFilterModal={true}
              showResetButton={true}
              // we can not get payments against Warehouse
              // filters={['warehouse']}
              filters={['dynamicContacts']}
              type="customer"
              dateRange={{
                value: filters.dateRange,
                onChange: this.props.handleDateRangeChange,
              }}
              dynamicContacts={{
                options: [],
                selected:
                  filters.contactID !== null ? filters.contactID : 'all',
                onChange: this.props.handleCustomerSelect,
              }}
              warehouse={{
                options: warehouseList,
                selected:
                  filters.warehouseID !== null ? filters.warehouseID : 'all',
                onChange: this.props.handleWarehouseSelect,
              }}
              viewPerPage={filters.viewPerPage}
              totalPage={totalPage}
              currentPage={filters.page}
              onPageChange={(value) => this.props.handlePaginate(value, 'page')}
              onViewPerPageChange={(value) =>
                this.props.handlePaginate(value, 'viewPerPage')
              }
              hasMobile={this.props.hasMobile}
            />
          </Header>

          <div className="float-left w-100 info_section">
            <div className="float-left">
              <ReportOrganization />
            </div>
            <div className="float-right">
              <div className="reports--filters-container">
                <ReportFilters
                  showResetButton={true}
                  filters={[]}
                  dateRange={{
                    value: filters.dateRange,
                    onChange: this.props.handleDateRangeChange,
                  }}
                />
              </div>
              {/* <div
                className="float-right"
                style={{
                  marginTop: '-20px',
                  fontWeight: 'normal',
                  color: 'grey',
                }}
              >
                To filter data, use "Customize Report" button
              </div> */}
            </div>
          </div>
          <div className="float-left w-100 listing_section payment_recived_report refund_report">
            <ReportTable
              list={formattedReportData}
              tableHeaders={tableHeaders}
              tableData={tableData}
              rightAlignColumns={rightAlignColumns}
              bottomRow={reportTotal}
              handleSorting={this.props.handleSorting}
              loading={reportLoading}
              orderBy={this.props.orderBy}
              sortBy={this.props.sortBy}
              className="refund-report-main"
            />
          </div>
        </Layout>
      </div>
    );
  }
}

RefundreportbyCustomer.defaultProps = {
  filters: {},
  handleDateRangeChange: () => {},
  handleWarehouseSelect: () => {},
  handleSorting: () => {},
  handlePaginate: () => {},
  headerTitle: () => {},
};

export default ReportHOC({
  title: reportTitles.RefundreportbyCustomer,
  filterKeys: [
    'startDate',
    'endDate',
    'warehouseID',
    'sortBy',
    'orderBy',
    'page',
    'viewPerPage',
  ], // name of filters in HOC
})(RefundreportbyCustomer);
