import React from 'react'; // Fragment
import './StockQuantity.css';
const StockQuantity = ({
  accountingStock,
  physicalStock,
  oppeningStock,
  OpeningStockValue,
  reorder,
  symbol,
  inventory_type,
  inventoryAccount
}) => {
  return (
    <div className="stock_row stock_mob-row">
      <div className="float-left w-33 pr-10">
        <div className="stock_row_inner float-left w-100 background_custom p-10 height_custom">
          <span className="upper_text">Accounting Stock</span>
          <hr />
          <div>
            <div className="stockfields">
              <span>Stock on Hand</span>
              <span>
                {' '}
                {inventory_type && inventory_type === 'inventory'
                  ? accountingStock.stock_on_hand
                  : "-"}
              </span>
            </div>
            <hr />
            <div className="stockfields">
              <span>Comitted Stock</span>
              <span>
                {' '}
                {inventory_type && inventory_type === 'inventory'
                  ? accountingStock.committed_stock
                  : "-"}
              </span>
            </div>
            <hr />
            <div className="stockfields">
              <span>Available for Sale</span>
              <span>
                {' '}
                {inventory_type && inventory_type === 'inventory'
                  ? accountingStock.available_sale_stock
                  : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="float-left w-33 pr-10 ">
        <div className="stock_row_inner float-left w-100 background_custom p-10 height_custom">
          <span className="upper_text">Physical Stock</span>
          <hr />
          <div>
            <div className="stockfields">
              <span>Stock on Hand</span>
              <span>
                {' '}
                {inventory_type && inventory_type === 'inventory'
                  ? physicalStock.stock_on_hand
                  : "-"}
              </span>
            </div>
            <hr />
            <div className="stockfields">
              <span>Comitted Stock</span>
              <span>
                {' '}
                {inventory_type && inventory_type === 'inventory'
                  ? physicalStock.committed_stock
                  : "-"}
              </span>
            </div>
            <hr />
            <div className="stockfields">
              <span>Available for Sale</span>
              <span>
                {' '}
                {inventory_type && inventory_type === 'inventory'
                  ? physicalStock.available_sale_stock
                  : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="float-left w-33 pr-10">
        <div className="stock_row_inner float-left w-100 background_custom p-10 height_custom">
          <div className="stock_information">
            <div className="float-left w-100">
              <span className="float-left">Inventory Account</span>
              <span className="float-right">
                {inventory_type && inventory_type === 'inventory'
                  ? inventoryAccount?.title
                  : "-"}
              </span>
            </div>
            <div className="float-left w-100">
              <span className="float-left">Opening Stock</span>
              <span className="float-right">
                {inventory_type && inventory_type === 'inventory'
                  ? oppeningStock
                  : "-"}
              </span>
            </div>
            <div className="float-left w-100 d-flex space-between">
              <span className="float-left">Opening Stock Value</span>
              <span className="float-right" style={{width: '40%',textAlign: 'right',wordBreak: 'break-word'}}>
                {' '}
                {/* {`${symbol}`} */}
                {inventory_type && inventory_type === 'inventory'
                  ? `${symbol} ${OpeningStockValue.toFixed(2)}`
                  : "-"}
              </span>
            </div>
            <div className="float-left w-100">
              <span className="float-left">Reorder Value</span>
              <span className="float-right">
                {' '}
                {inventory_type && inventory_type === 'inventory' ? reorder : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockQuantity;
