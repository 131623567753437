import React, { Component } from 'react';
import GeneralPreference from './General/GeneralPreference';
import SalesOrderPreference from './SalesOrderPreference/SalesOrderPreference';
import PurchaseOrderPreference from './PurchaseOrderPreference/PurchaseOrderPreference';
import InvoicePreference from './InvoicePreference/InvoicePreference';
import BillPreference from './BillPreference/BillPreference';
import SaleReturnPolicyPreference from './SaleReturnPolicyPreference/SaleReturnPolicyPreference';
import PrintandPdfPreference from './PrintandPdfPreference/PrintandPdfPreference';
import DashboardPrefrences from './DashboardPrefrences/DashboardPrefrences';
import EmailPrefrence from './EmailPreference/EmailPrefrence';
import Layout, { Header, Body } from '../../Components/Layout/Layout';
import NavLayout from '../../Components/Layout/NavLayout';
import './Preference.css';
import Prefix from './Prefix/Prefix';

class Preference extends Component {
  routes = [
    {
      title: 'General',
      path: '/preference',
      component: GeneralPreference,
    },
    {
      title: 'Sales Order',
      path: '/preference/salesorder',
      component: SalesOrderPreference,
    },
    {
      title: 'Invoice',
      path: '/preference/invoice',
      component: InvoicePreference,
    },
    {
      title: 'Purchase Order',
      path: '/preference/purchaseorder',
      component: PurchaseOrderPreference,
    },
    {
      title: 'Bill',
      path: '/preference/bill',
      component: BillPreference,
    },
    {
      title: 'Credit Note Deduction Policy',
      path: '/preference/SaleReturnPolicyPreference',
      component: SaleReturnPolicyPreference,
    },
    {
      title: 'Prefixes',
      path: '/preference/Prefix',
      component: Prefix,
    },
    {
      title: 'Prints & PDF',
      path: '/preference/pdfandprint',
      component: PrintandPdfPreference,
    },
    {
      title: 'Dashboard',
      path: '/preference/dashboard',
      component: DashboardPrefrences,
    },
    {
      title: 'Email',
      path: '/preference/email',
      component: EmailPrefrence,
    },
  ];

  componentDidMount() {
    document.title = 'Preferences';
  }

  renderPreference() {
    return (
      <div className="preference">
        <NavLayout routes={this.routes} handleToast={this.props.handleToast} />
      </div>
    );
  }

  render() {
    return (
      <Layout>
        <Header title="Preferences"></Header>
        <Body>{this.renderPreference()}</Body>
      </Layout>
    );
  }
}

export default Preference;
