import React, { Fragment } from 'react';

import getDate from '../../../Helpers/Date/GetDate';

import Table from '../../../Components/Table/Table';
import EmptyTable from '../../../Components/ListTable/EmptyTable';
import Loader from '../../../Components/Loader/Loader';
import ListTableImageColumn from '../../../Components/ListTable/ListTableImageColumn';

import './Report.css';
//  import classNames from 'classnames';

const ReportTable = ({
  list,
  tableHeaders = [],
  tableData = [],
  rightAlignColumns = [],
  handleSorting = () => null,
  tableType = 'regular', // regular, warehouse, fifo
  bottomRow,
  loading,
  orderBy,
  sortBy,
  className = '',
  // paymentReport = false
}) => {
  const renderFifoTable = () => {
    return (
      <table className="table sub_tabs_transaction_main report--table float-left w-100 inventory_listing_Table_inner fifo_Cost">
        <thead className="table-header" style={{ paddingLeft: '0px' }}>
          <tr className="table--row col-span">
            <th colSpan="7" className="toprow-left">
              Item In
            </th>
            <th colSpan="4" className="toprow-right">
              Item Out
            </th>
          </tr>
          <tr className="table--row">
            <th onClick={() => handleSorting('date')} className="pointer">
              Date
            </th>
            <th>Transactions</th>
            <th>Received From</th>
            <th>Warehouse</th>
            <th>Item Name</th>
            <th className="right-align">Quantity</th>
            <th className="right-align add-border">Total</th>
            <th>Date</th>
            <th>Transactions</th>
            <th>Dispersed To</th>
            <th className="right-align">Qty Dispersed</th>
          </tr>
        </thead>
        <tbody style={{ paddingLeft: '0px' }}>
          {list.map((data, index) => {
            return (
              <Fragment key={index}>
                {data.product_out_transactions.length === 0 ? (
                  <tr key={index}>
                    <td>
                      {!data.product_out_overflow ? getDate(data.date) : null}
                    </td>
                    <td>{data.transaction}</td>
                    <td>{data.received_from}</td>
                    <td>{data.warehouse_id}</td>
                    <td>
                      {data.item_name}{' '}
                      <span className="small-td-info">{data.sku}</span>
                    </td>
                    <td className="right-align">
                      {data.quantity}
                      <span className="small-td-info">{data.unit}</span>
                      {data.quantity_remaining !== 0 && (
                        <span className="small-td-info">
                          Remaining: {data.quantity_remaining}
                        </span>
                      )}
                    </td>
                    <td className="right-align add-border">
                      {data.total ? data.total.toFixed(2) : 0}
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                ) : (
                  data.product_out_transactions.map((out, index) => {
                    return (
                      <tr key={index} className={index !== 0 ? 'spanned' : ''}>
                        {index < 1 && (
                          <Fragment>
                            <td rowSpan={data.product_out_transactions.length}>
                              {!data.product_out_over_flow
                                ? getDate(data.date)
                                : null}
                            </td>
                            <td rowSpan={data.product_out_transactions.length}>
                              {data.transaction}
                            </td>
                            <td rowSpan={data.product_out_transactions.length}>
                              {data.received_from}
                            </td>
                            <td rowSpan={data.product_out_transactions.length}>
                              {data.warehouse_id}
                            </td>
                            <td rowSpan={data.product_out_transactions.length}>
                              {data.item_name}{' '}
                              <span className="small-td-info">{data.sku}</span>
                            </td>
                            <td
                              rowSpan={data.product_out_transactions.length}
                              className="right-align"
                            >
                              {data.quantity}
                              <span className="small-td-info">{data.unit}</span>
                              {data.quantity_remaining !== 0 && (
                                <span className="small-td-info">
                                  Remaining: {data.quantity_remaining}
                                </span>
                              )}
                            </td>
                            <td
                              rowSpan={data.product_out_transactions.length}
                              className="right-align add-border"
                            >
                              {data.total.toFixed(2)}
                            </td>
                          </Fragment>
                        )}
                        {index >= 1 && (
                          <Fragment>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                          </Fragment>
                        )}
                        <td>{getDate(out.date)}</td>
                        <td>{out.transaction}</td>
                        <td>{out.dispersed_to}</td>
                        <td className="right-align">
                          {out.quantity_dispersed}{' '}
                          <span className="small-td-info">{out.unit}</span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderWarehouseTable = () => {
    return (
      <table
        className="table sub_tabs_transaction_main report--table float-left w-100 inventory_listing_Table_inner"
        style={{}}
      >
        <thead className="table-header hassan">
          <tr className="table--row">
            <th
              onClick={() => handleSorting('product_name')}
              className="pointer"
            >
              Item Name
            </th>
            <th>SKU</th>
            <th>Warehouse Name</th>
            <th className="right-align">Quantity Ordered</th>
            <th className="right-align">Quantity In</th>
            <th className="right-align">Quantity Out</th>
            <th className="right-align">Quantity Returned</th>
            <th className="right-align">Stock on Hand</th>
          </tr>
        </thead>
        <tbody>
          {list.map((data, index) => {
            return (
              <Fragment key={index}>
                {data.map((singleItem, index) => {
                  // console.log('singleItem',data.length)
                  return (
                    <tr key={index} className={index !== 0 ? 'spanned' : ''}>
                      {index > 0 ? (
                        <></>
                      ) : (
                        <td rowSpan={data.length ? data.length : ''}>
                          <ListTableImageColumn
                            name={singleItem.product_name}
                            sku={singleItem.sku}
                            showSKU={false}
                            images={singleItem.images}
                          />
                        </td>
                      )}
                      {index > 0 ? (
                        <></>
                      ) : (
                        <td rowSpan={data.length ? data.length : ''}>
                          {singleItem.sku}
                        </td>
                      )}
                      <td>{singleItem.warehouse_name}</td>
                      <td className="right-align">
                        {singleItem.quantity_ordered}
                      </td>
                      <td className="right-align">{singleItem.quantity_in}</td>
                      <td className="right-align">{singleItem.quantity_out}</td>
                      <td className="right-align">
                        {singleItem.returned_quantity}
                      </td>
                      <td className="right-align">
                        {singleItem.stock_in_hand}
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderCommittedStockTable = () => {
    let old_record = null;
    let record_same = false;
    let count = 0;

    return (
      <table
        className={`table sub_tabs_transaction_main report--table float-left w-100 inventory_listing_Table_inner ${className}`}
        style={{}}
      >
        <thead className="table-header hassan">
          <tr className="table--row">
            <th
              onClick={() => handleSorting('product_name')}
              className="pointer"
            >
              Transaction#
            </th>
            <th>Item Name</th>
            <th>Committed Stock</th>
          </tr>
        </thead>
        <tbody>
          {list.map((data, index) => {
            count = count + 1;
            if (index === 0) {
              old_record = data.SO_no;
            } else if (old_record === data.SO_no) {
              old_record = data.SO_no;
              record_same = true;
            } else if (old_record !== data.SO_no) {
              old_record = data.SO_no;
              record_same = false;
            }
            return (
              <tr key={index} className={index !== 0 ? 'spanned' : ''}>
                {index === 0 ? (
                  <td>{data.SO_no}</td>
                ) : record_same === false && index > 0 ? (
                  <td>{data.SO_no}</td>
                ) : (
                  <td></td>
                )}
                <td>{data.item_name}</td>
                <td className="right-align">{data.quantity}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="table--bottom-row bold">
        <tr>
          <td
            colSpan={tableData.length - Object.values(bottomRow).length}
            style={{
              textAlign: 'right',
              paddingRight: '30px',
            }}
          >
            Total
          </td>
          {Object.values(bottomRow).map((col, index) => (
            <td key={index} className="right-align">
              {col}
            </td>
          ))}
        </tr>
        </tfoot>
      </table>
    );
  };

  const renderTable = () => {
    if (tableType === 'warehouse') return renderWarehouseTable();
    else if (tableType === 'fifo') return renderFifoTable();
    else if (tableType === 'committedstock') return renderCommittedStockTable();
    else {
      return (
        <Table
          className={`report--table float-left w-100 inventory_listing_Table_inner UIUX_report_table_inner ${className} ${tableData?.length > 8 ? 'nine-col' : ''
            }`}
          list={list}
          tableHeaders={tableHeaders}
          tableData={tableData}
          rightAlignColumns={rightAlignColumns}
          handleSorting={handleSorting}
          hasSKU
          orderBy={orderBy}
          sortBy={sortBy}
          bottomRow={bottomRow}
        // paymentReport={paymentReport}
        />
      );
    }
  };

  if (loading) return <Loader />;
  if (!list.length) return <EmptyTable />;
  return (
    <div
      className={` reports--body so-tbletabs reports-main-container ${className === 'order-fulfillment-by-item' ? 'order-fulfillment-report' : ''}  ${className === 'sales-by-sales-by-sales-person'
        ? 'sales-person-report-main'
        : ''
        } ${tableType === 'fifo' ? 'fifo-report-main' : ''}`}
    >
      {renderTable()}
    </div>
  );
};

export default ReportTable;
