import React, { Component } from 'react';
import './DashboardTopSellingItems.css';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import NoDashboardDataFound from '../../../Components/NotFound/NoDashboardDataFound';
import {
  restRequest,
  imagePathS3,
  imagePath,
} from '../../../Helpers/RequestHelper';
import { formatNumber } from '../../../Helpers/FormatNumber';
import Loader from '../../../Components/Loader/Loader';
import { getObjFromLS, setObjInLS } from '../../../Helpers/LocalStorage';
import AccessDenied from '../../../Components/AccessDenied/AccessDenied';
import DataError from '../../../Components/DashboardDataError/DB_DataError';
import { checkError } from '../../../Helpers/AuthHelper';
import { setDashboardAmount } from '../../../Helpers/setAmount';
import { ImageUrl } from '../../../Helpers/ImageUrl';
const settings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToScroll: 1,
  slidesToShow: 1,
  pauseOnHover: true,
  autoplay: true,
  autoplaySpeed: 10000,
};

export default class DashboardTopSellingItems extends Component {
  state = {
    items: [],
    currentDateRange: 'month',
    loading: true,
    currency: '',
    filter: 'quantity',
    accessDenied: false,
    accessError: false,
  };
  dashboard_preferences = JSON.parse(
    localStorage.getItem('dashboardPreferences')
  );

  componentDidMount() {
    this.handleUpdateData(
      this.dashboard_preferences.top_selling_items,
      'quantity'
    );

    document
      .getElementById('click_nav_arrow_button')
      .addEventListener('click', this.myFunction);

    window.addEventListener('resize', this.myFunction);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.myFunction);
    window.removeEventListener('resize', this.myFunction);
  }

  myFunction = () => {
    this.handleUpdateData(
      this.props.currentDateRange.top_selling_items,
      this.props.filter,
      this.props.startData,
      this.props.endDate
    );
  };

  renderImageSrc(image) {
    if (image.length > 0) {
      if (image[0].includes('http:')) {
        return image[0];
      } else if (image[0].includes('https:')) {
        return image[0];
      } else {
        return ImageUrl(image[0]);
      }
    } else {
      return imagePathS3(image[0]);
    }
  }
  // handleUpdateData(dateRangeFilter,filter) {

  handleUpdateData(
    currentDateRange = this.dashboard_preferences.top_selling_items,
    filter,
    startData,
    endDate
  ) {
    this.setState({ loading: true, filter: filter });
    // restRequest('get', `dashboard/topsellingitems/${currentDateRange === 'custom' ? `custom?starting_date=${startData}&ending_date=${endDate}` :  currentDateRange ? currentDateRange : this.props.currentDateRange}/${filter}`).then(res => {
    let customUrl = `dashboard/topsellingitems/custom/${filter}?starting_date=${startData}&ending_date=${endDate}`;
    let dateRange = `dashboard/topsellingitems/${
      currentDateRange ? currentDateRange : this.props.currentDateRange
    }/${filter}`;
    //    restRequest('get', `dashboard/topsellingitems/${currentDateRange === 'custom' ? `custom?starting_date=${startData}&ending_date=${endDate}` :  currentDateRange ? currentDateRange : this.props.currentDateRange}/${filter}`).then(res => {
    restRequest('get', currentDateRange === 'custom' ? customUrl : dateRange)
      .then((res) => {
        this.setState({
          currency: res.currency,
          items: res.items,
          loading: false,
        });
      })
      .catch((error) => {
        checkError(error);
        if (error.response.status === 403) {
          this.setState({
            accessDenied: true,
            loading: false,
          });
        } else {
          this.setState({
            accessError: true,
            loading: false,
          });
        }
      });
  }
  componentWillReceiveProps(nextProps) {
    //console.log(nextProps, this.props);
    // JSON.stringify(nextProps) !== JSON.stringify(this.props) && this.handleUpdateData(nextProps.currentDateRange ,nextProps.filter)
    (nextProps.currentDateRange !== this.props.currentDateRange ||
      nextProps.startData !== this.props.startData ||
      nextProps.endDate !== this.props.endDate ||
      nextProps.filter !== this.props.filter) &&
      this.handleUpdateData(
        nextProps.currentDateRange.top_selling_items,
        nextProps.filter,
        nextProps.startData,
        nextProps.endDate
      );
  }
  render() {
    let datafromLS = getObjFromLS('module');
    if (this.state.loading) return <Loader />;
    if (this.state.accessDenied)
      return (
        <AccessDenied className="small dashboard " type="Top Selling Items" />
      );
    if (this.state.accessError)
      return (
        <DataError className="small dashboard " type="Top Selling Items" />
      );
    if (this.state.items.length === 0)
      return (
        <NoDashboardDataFound message="No top selling item at the moment" />
      );
    return (
      <>
        <div className="slick-slider-wrapper">
          <Slider {...settings}>
            {this.state.items.map((item, index) => {
              return (
                <div className="slider-widget">
                  <div className="image_section">
                    <Link
                      key={index}
                      to={`/items/${item.item_id}`}
                      onClick={() => {
                        setObjInLS('module', {
                          ...datafromLS,
                          id: item.item_id,
                          urlPath: '/items',
                          moduleName: 'items',
                        });
                      }}
                    >
                      <img
                        className="dashoard_slider_image"
                        src={
                          this.renderImageSrc(item.images) ||
                          imagePathS3(item.images)
                        }
                        alt={item.name}
                      />
                    </Link>
                  </div>

                  <div className="slider-widget-right">
                    <div
                      className="mb_10 line_clamp"
                      title={item.name}
                      style={{ fontWeight: 'bold' }}
                    >
                      <Link
                        key={index}
                        to={`/items/${item.item_id}`}
                        onClick={() => {
                          setObjInLS('module', {
                            ...datafromLS,
                            id: item.item_id,
                            urlPath: '/items',
                            moduleName: 'items',
                          });
                        }}
                      >
                        {item.name}
                      </Link>
                    </div>

                    {this.state.filter === 'amount' && (
                      <div className="mb_10 clearfix">
                        <span className="dashoard_slider_sub_details">
                          Total Sale
                        </span>
                        <span className="bold_text">
                          {this.state.currency}
                          {setDashboardAmount(item.total_sale)}
                        </span>
                      </div>
                    )}

                    <div className="mb_10 clearfix">
                      <span className="dashoard_slider_sub_details">
                        Quantity Sold
                      </span>
                      <span className="bold_text">
                        {item.quantity_sold} {item.unit}
                      </span>
                    </div>

                    <div className="mb_10 clearfix">
                      <span className="dashoard_slider_sub_details">Price</span>
                      <span className="bold_text">
                        {`${this.state.currency}${setDashboardAmount(
                          item.price
                        )}`}
                      </span>
                    </div>

                    <div className="mb_10 clearfix">
                      <span className="dashoard_slider_sub_details">
                        In Stock
                      </span>
                      <span className="bold_text">{item.in_stock}</span>
                    </div>

                    {this.state.filter === 'quantity' && (
                      <div className="">
                        <span className="dashoard_slider_sub_details">
                          Total Sale
                        </span>
                        <span className="bold_text">
                          {`${this.state.currency}${setDashboardAmount(
                            item.total_sale
                          )}`}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </>
    );
  }
}
