import React, { useState } from 'react';
import { useEffect } from 'react';
import { restRequest } from '../../Helpers/RequestHelper';
import getDate from '../../Helpers/Date/GetDate';
import getStatusColor from '../../Helpers/GetStatusColor';
import EmptyTable from './EmptyTable';
import { Loader } from '../../Components/index';
import { Link } from 'react-router-dom';
const TableContent = (props) => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const url = props.url;
  const currency = props.currency;
  const showDetail = props.showdetail;
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    let responcedata = [];
    setLoader(true);
    restRequest('get', url)
      .then((res) => {
        if (res.length > 0) {
          Object.keys(res).forEach((key) => responcedata.push(res[key]));
          setData(responcedata);
          //console.log(headers);
        }
        setLoader(false);
      })
      .catch((e) => {
        // console.log(e);
        setLoader(false);
      });
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : data.length ? (
        <div className="issued_SO_PO">
          <table
            className={`list-table border ${
              showDetail === 'Bills' ? 'show-bills' : 'intimation-main'
            }`}
          >
            <thead>
              <tr>
                <th>{showDetail === 'Bills' ? 'Bill#' : 'Invoice#'}</th>
                <th>Status</th>
                <th>Date</th>
                <th>Total</th>
                <th>{showDetail === 'Bills' ? 'Bill Due' : 'Payment Due'}</th>
                <th
                  className={
                    showDetail === 'Bills'
                      ? 'hide-intimation d-none'
                      : 'show-intimation'
                  }
                >
                  Intimation
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((p_invoice, index) => (
                <tr key={index}>
                  <td>
                    {showDetail === 'Bills'
                      ? p_invoice.bill_no
                      : p_invoice.invoice_no}
                  </td>
                  <td>
                    <div className="list-table--link">
                      <span className={getStatusColor(p_invoice.status)}>
                        {p_invoice.status}
                      </span>{' '}
                    </div>
                  </td>
                  <td>
                    {getDate(
                      showDetail === 'Bills'
                        ? p_invoice.due_date
                        : p_invoice.invoice_date
                    )}
                  </td>
                  <td>
                    {currency}
                    {p_invoice.total}
                  </td>
                  <td style={{ margin: 'auto' }}>
                    {currency}
                    {showDetail === 'Bills'
                      ? p_invoice.balance_due
                      : p_invoice.payment_due}
                  </td>
                  <td
                    // style={
                    //   showDetail === 'Bills'
                    //     ? { display: 'none' }
                    //     : { display: 'inline' }
                    // }
                    className={
                      showDetail === 'Bills'
                        ? 'hide-intimation d-none'
                        : 'show-intimation'
                    }
                  >
                    <div className="btn-container">
                      <Link
                        to={`/invoices/email/${p_invoice.id}`}
                        className="next-btn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Send
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyTable inModal={true} />
      )}
    </>
  );
};

export default TableContent;
