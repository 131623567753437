const skipButtonClicked = JSON.parse(localStorage.getItem('skipBtnoldAccount'));
const organization = JSON.parse(
  localStorage.getItem('currentOrganization')
)?.mapped_account_status;
export const getOldAcc = () => {
  if (!(!!skipButtonClicked && !organization)) {
    return false;
  } else {
    return true;
  }
};
