import React from 'react';
import { MdClose } from '../../Common/Icons';
import { imagePath } from '../../Helpers/RequestHelper';
import styles from './ImagePreview.module.css';
import { ImageUrl } from '../../Helpers/ImageUrl';

const ImagePreview = ({ images = [], onDeleteImage,isOrganization=true }) => {
  console.log(isOrganization);
  
  return (
    <div className={styles?.imagePreviewContainer}>
      {images?.length > 0 &&
        images?.map((img, index) => {
          return (
            <div
              key={index}
              className={styles.imagePreview}
              // style={{
              //   backgroundImage: !img?.preview
              //     ? `url(${imagePath(img)})`
              //     : `url(${img?.preview})`,
              // }}
            >
              <>
              <div className={styles.Organization_img__outer}>
              {<img src={!img?.preview
                ? `${imagePath(img)}`
                : `${img?.preview}`}  className={styles.organization__img} />}
                </div>
              <button
                name={img?.name}
                type="button"
                className="button--image-delete"
                onClick={() => onDeleteImage(img)}
              >
                <MdClose className={styles.closeIconTransition} />
              </button>
              </>
            </div>
          );
        })}
    </div>
  );
};

export default ImagePreview;
