import React, { useEffect, useState, useCallback } from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { CheckIcon } from '../../../Assets/Mixed/SearchIcons';
import DeleteIcon from '../../../Assets/General/DeleteIcon';
import {
  required,
  ValidateNumber,
  moreThanZero,
} from '../../../Helpers/Validator';
import { validate, getError } from '../FormHelper';

import Field from './../Field/Field';
import ListTableImageColumn from '../../ListTable/ListTableImageColumn';
import FormAction from '../FormAction';
import Form from '../Form';
import SmallLoader from '../../SmallLoader/SmallLoader';

import './BulkAddItems.css';
import { matchSorter } from 'match-sorter';
import { confirmationAlert } from '../../../Helpers';
import { Loader } from '../../UnsyncOrders';
import InfiniteScroll from 'react-infinite-scroll-component';
import { restRequest } from '../../UnsyncOrders';
import { debounce } from 'lodash';

const getYupValidationSchema = () => {
  return Yup.object().shape({
    selectedItems: Yup.array().of(
      Yup.object().shape({
        quantity: required(moreThanZero(ValidateNumber)),
      })
    ),
  });
};

const formEnhancer = withFormik({
  validate: validate(getYupValidationSchema),
  mapPropsToValues: () => ({
    selectedItems: [],
  }),
  // mapPropsToStatus: (props) => ({
  //   items: props.items,
  //   itemList: props.items,
  // }),
  handleSubmit: (values, { props, setSubmitting }) => {
    if (!values.selectedItems.length) {
      props.handleToast('Select some items for bulk add.', 'error');
      setSubmitting(false);
    } else {
      props.setModalstate(true);
      props.onSubmit(values.selectedItems);
    }
  },
});

const BulkAddItems = ({
  values,
  status,
  handleSubmit,
  isSubmitting,
  handleBlur,
  setFieldValue,
  setStatus,
  errors,
  touched,
  rateKey,
  currencySymbol,
  close,
  moduleName,
  handlePrompt = () => null,
}) => {
  const [loading, setloading] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [totalItems, settotalItems] = useState(null);
  const [page, setPage] = useState(1);
  const [nextPage, setnextpage] = useState('');
  const [items, setitems] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [search, setSearch] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [barcodeselection, setbarcodeselection] = useState();

  useEffect(() => {
    barcodeselection && handleSelectItem(barcodeselection, 'barcode');
  }, [barcodeselection]);
  useEffect(() => {
    setisloading(true);
    debounceLoadData(search);
  }, [search]);
  useEffect(() => {
    if (disabled) {
      setTimeout(() => {
        setDisabled(false);
      }, 1000);
    }
  }, [disabled]);

  useEffect(() => {
    const { checkout } = validationBulkItemModal(values);
    handlePrompt(!checkout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const resetPage = () => {
    setPage(1);
    return true;
  };
  const fetchData = async (value = '') => {
    if (totalItems === items.length) {
      return null;
    }
    let url = `${moduleName}/items?page=${page}&search=${value.replace(
      '&',
      '%26'
    )}`;
    await restRequest('get', url)
      .then((res) => {
        const itemData = res?.data.map((item) => ({
          ...item,
          quantity: 1,
        }));

        if (itemData.length === 1) {
          if (
            (itemData[0].name &&
              itemData[0].name.toLowerCase() === value.toLowerCase()) ||
            (itemData[0].sku &&
              itemData[0].sku.toLowerCase() === value.toLowerCase()) ||
            (itemData[0].unit &&
              itemData[0].unit.toLowerCase() === value.toLowerCase()) ||
            (itemData[0].ean &&
              itemData[0].ean.toLowerCase() === value.toLowerCase()) ||
            (itemData[0].isbn &&
              itemData[0].isbn.toLowerCase() === value.toLowerCase()) ||
            (itemData[0].mpn &&
              itemData[0].mpn.toLowerCase() === value.toLowerCase()) ||
            (itemData[0].upc &&
              itemData[0].upc.toLowerCase() === value.toLowerCase())
          ) {
            setbarcodeselection(itemData[0]);
            handleBack();
            return;
          } else {
            setitems([...items, ...itemData]);
            setloading(false);
            setisloading(false);
          }
        } else {
          setloading(false);
          setisloading(false);
          settotalItems(res.total);
          setitems(page === 1 ? itemData : [...items, ...itemData]);
          setItemList(page === 1 ? itemData : [...items, ...itemData]);
          setnextpage(res.next_page_url);
        }
        if (res.current_page != res.last_page) {
          setPage(page + 1);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const validationBulkItemModal = (data) => {
    const { selectedItems = [] } = data;
    let checkout = true;
    if (selectedItems.length) {
      checkout = false;
    }
    return { checkout };
  };
  const handleCancel = () => {
    const { checkout } = validationBulkItemModal(values);
    let response = true;
    if (!checkout) {
      response = confirmationAlert();
    }
    if (checkout || response) close();
  };

  const { selectedItems } = values;

  // const { items, itemList } = status;
  const handleSelectItem = (selectedItem, type) => {
    let updatedSelectedItems = [];
    if (updatedSelectedItems.length === 0) {
      updatedSelectedItems = [...selectedItems, selectedItem];
    }
    if (updatedSelectedItems.find((item) => item.id !== selectedItem.id)) {
      updatedSelectedItems = [...selectedItems, selectedItem];
    }
    if (values.selectedItems.find((item) => item.id === selectedItem.id)) {
      let selectedItemIndex = selectedItems.findIndex(
        (f) => f.id === selectedItem.id
      );
      if (type === 'click') selectedItems.splice(selectedItemIndex, 1);
      else
        selectedItems[selectedItemIndex].quantity =
          selectedItems[selectedItemIndex].quantity + 1;
      updatedSelectedItems = selectedItems;
    }
    if (updatedSelectedItems.length > 0) handlePrompt();
    else handlePrompt(false);
    setFieldValue('selectedItems', updatedSelectedItems);
  };

  const handleRemoveItem = (removeItem) => {
    const updatedSelectedItems = selectedItems.filter(
      (item) => item.id !== removeItem.id
    );

    setFieldValue('selectedItems', updatedSelectedItems);
  };

  const handleItemQuantityChange = (quantityValue, index) => {
    let quantity = quantityValue.floatValue;
    if (quantity !== undefined) {
      let quantString = quantity.toString();
      if (quantString.length > 10) {
        quantString = quantString.slice(0, 10);
      }
      quantity = parseFloat(quantString);
    }
    const itemList = [...selectedItems];
    const currentItem = itemList[index];

    itemList[index] = {
      ...currentItem,
      quantity,
    };

    setFieldValue('selectedItems', itemList);
  };

  const textInput = React.useRef(null);
  React.useEffect(() => {
    if (!search) {
      textInput && textInput.current && textInput.current.focus();
    }
  }, [search]);

  const handlekeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setDisabled(true);
    }
  };

  const debounceLoadData = useCallback(debounce(fetchData, 500), []);

  const handleSearchInputChange = (event) => {
    setPage(1);
    setSearch(event.target.value);
  };

  const handleBack = () => {
    resetPage();
    setSearch('');
  };

  const renderItems = () => {
    const selectedItemsIds = selectedItems.map(
      (selectedItem) => selectedItem.id
    );
    return (
      <>
        <InfiniteScroll
          dataLength={items?.length}
          next={fetchData}
          hasMore={totalItems === items.length ? false : true}
          loader={<Loader />}
          height={440}
          style={{ overflow: 'auto' }}
        >
          {items && items.length > 0 ? (
            items.map((item, index) => (
              <div
                key={index}
                className="bulk-add-items--item-row"
                onClick={() => {
                  handleSelectItem(item, 'click');
                  textInput && textInput.current && textInput.current.focus();
                }}
                title="Click to Add/Remove Item(s)"
              >
                <ListTableImageColumn
                  name={item.name}
                  sku={item.sku}
                  images={item.images}
                />
                <div className="bold word-break-all">
                  {currencySymbol}
                  {item[rateKey]}
                </div>
                {selectedItemsIds.includes(item.id) && <CheckIcon />}
              </div>
            ))
          ) : (
            <div className="no-item">
              <h1>No item found</h1>
              <br />
              <button
                type="button"
                style={{
                  padding: '5px 20px',
                  backgroundColor: '#33a348',
                  color: 'white',
                }}
                onClick={handleBack}
              >
                {' '}
                Clear{' '}
              </button>
            </div>
          )}
        </InfiniteScroll>
      </>
    );
  };

  const renderSelectedItems = () => {
    return selectedItems.map((item, index) => (
      <div key={index} className="bulk-add-items--item-row">
        <ListTableImageColumn
          name={item.name}
          sku={item.sku}
          images={item.images}
        />
        <Field
          id={`selectedItems[${index}].quantity`}
          className="no-padding"
          type="number"
          size="full"
          suffix={item.unit ? item.unit : ''}
          value={item.quantity}
          allowNegative={false}
          thousandSeparator=","
          onValueChange={(value) => handleItemQuantityChange(value, index)}
          onBlur={handleBlur}
          error={getError(errors, touched, `selectedItems[${index}].quantity`)}
        />
        <button
          type="button"
          className="close-btn button"
          onClick={() => handleRemoveItem(item)}
        >
          <DeleteIcon className="bin" />
        </button>
      </div>
    ));
  };
  return (
    <Form onSubmit={handleSubmit}>
      {isSubmitting || loading ? (
        <div className="issued_SO_PO bulk_inv">
          <span className="test">Please wait...</span>{' '}
        </div>
      ) : (
        <div className="bulk-add-items ">
          <div className="float-left w-50">
            <div className="bulk-add-items--header bulk-add-items--search float-left w-100">
              <div className="items float-left w-20">Items</div>
              <Field
                autoFocus={true}
                type="input-uncontrolled"
                size="full"
                className="no-padding float-left w-80"
                placeholder="Search Items"
                value={search}
                disabled={disabled}
                reference={textInput}
                handleChange={(event) => {
                  handleSearchInputChange(event);
                }}
                fromBulk
                handlekeyPress={handlekeyPress}
              />
              {search && (
                <span
                  style={{
                    position: 'absolute',
                    top: '14.2%',
                    right: '53%',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleBack()}
                >
                  X
                </span>
              )}
            </div>
            <div className="header_bulk_item float-left w-100">
              <div className="left_sec">Items</div>
              <div className="right_sec">Price</div>
            </div>
            <div className="bulk-add-items--items">
              {isloading ? <Loader /> : renderItems()}
            </div>
          </div>
          <div className="float-left w-50 right_sec">
            <div className="px_10 float-left w-100 mt_10">
              <div className="header_bulk_item float-left w-100">
                <div className="left_sec">
                  {' '}
                  Selected Items ({selectedItems.length})
                </div>
                <div className="right_sec">Add Quantity</div>
              </div>
            </div>

            <div className="bulk-add-items--items">{renderSelectedItems()}</div>
          </div>
        </div>
      )}

      <FormAction
        submitLabel={isSubmitting ? <SmallLoader /> : 'Add'}
        disabled={isSubmitting}
        onCancel={isSubmitting ? () => null : () => handleCancel()}
        cameFrom="bluk_items"
      />
    </Form>
  );
};

export default formEnhancer(BulkAddItems);
